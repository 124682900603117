import React, {useContext, useEffect, useState} from 'react';
import logo from 'assets/images/svg/shinobi-black.svg';
import logoMB from 'assets/images/LOGO_P_MB.png';
import logo1 from 'assets/images/svg/shinobi-white.svg';
import logo1MB from 'assets/images/LOGO_W_MB.png';
import logoMobile from 'assets/images/logo1.png';
import {NavLink, useHistory, useLocation} from 'react-router-dom';

import {useTranslation} from 'react-i18next';
import {languagesLabel} from '../../../common/i18n';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Divider,
  Hidden,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
  Drawer,
  useTheme,
  Grid,
} from '@material-ui/core';
import clsx from 'clsx';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {AuthContext} from '../../../store/AuthProvider';
import {ButtonMenuCustom} from '../../../components/StyledButtons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import WalletAccount from 'components/WalletAccount';
import ModalAddress from 'components/Modal/ModalAddress';
import {IS_DEFAULT} from 'common/constants';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {ReactComponent as tokens} from 'assets/images/svg/tokens.svg';
import {ReactComponent as swap} from 'assets/images/svg/swap.svg';
import {ReactComponent as stake} from 'assets/images/svg/stake.svg';
import {ReactComponent as home} from 'assets/images/svg/home.svg';
import {ReactComponent as access} from 'assets/images/svg/access.svg';
import {ReactComponent as createWallet} from 'assets/images/svg/create-wallet.svg';
import {Text} from 'App';
import CompanyMenu from './CompanyMenu';
import CloseIcon from '@material-ui/icons/Close';
import CompanyMenuMb from './CompanyMenuMb';

function MainNavbar() {
  const [t, i18n] = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const [languageMenu, setLanguageMenu] = useState(null);
  const [companyAnchor, setCompanyAnchor] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [scrollTop, setScrollTop] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openCompanyMb, setOpenCompanyMb] = React.useState(false);
  const classes = useStyles();
  const [auth, setAuth] = React.useState(false);

  const handleLanguageIconClick = (event) => {
    setLanguageMenu(event.currentTarget);
  };
  const handleLanguageMenuClose = (e) => {
    setLanguageMenu(null);
  };
  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguageMenu(null);
  };
  useEffect(() => {
    if (window.pageYOffset < 100 && window.location.pathname == '/') {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
    window.onscroll = () => {
      if (window.pageYOffset < 100 && window.location.pathname == '/') {
        setScrollTop(true);
      } else {
        setScrollTop(false);
      }
    };
  }, [window, window.location.pathname]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleCompanyMenuClose = () => {
    setCompanyAnchor(null);
  };

  const {wallet, removeWallet} = useContext(AuthContext);
  useEffect(() => {
    if (
      wallet.findIndex((val) => val.is_default === IS_DEFAULT['ACTIVE']) >= 0
    ) {
      setAuth(true);
    } else setAuth(false);
  }, [wallet]);
  const handleClose = () => {
    setOpenModal(false);
  };

  const logout = async () => {
    setAuth(false);
    await removeWallet();
    console.log('do something');
  };
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const path_url = useLocation();
  useEffect(() => {
    if (mobileMoreAnchorEl) handleMobileMenuClose();
  }, [path_url]);

  const handleOpenBlog = () => {
    if (i18n.language === 'ja-JP') {
      window.open('https://note.com/himegami/');
    } else {
      window.open('https://medium.com/@shinobiwallet');
    }
  };
  const renderMobileMenu = (
    <Drawer
      type="temporary"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <div role="presentation">
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleMobileMenuClose}>
            <CloseIcon style={{color: '#fff'}} />
          </IconButton>
        </div>
        {/* <Divider /> */}
        {auth ? (
          <List>
            <ListItem
              button
              className={classes.mobileItem}
              onClick={() => history.push('/')}>
              <ListItemText primary={'Home'} />
            </ListItem>

            <ListItem
              button
              className={classes.mobileItem}
              onClick={() => history.push('/buy-credit-card')}>
              <ListItemText primary={t('header.buy_crypto')} />
            </ListItem>
            <ListItem
              button
              className={classes.mobileItem}
              onClick={() => setOpenCompanyMb((pre) => !pre)}>
              <ListItemText>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  {t('header.company')} <ArrowDropDownIcon />
                </div>
              </ListItemText>
            </ListItem>
            {openCompanyMb && <CompanyMenuMb />}
            <ListItem
              button
              className={classes.mobileItem}
              onClick={() => history.push('/token-default')}>
              <ListItemText primary={'Tokens'} />
            </ListItem>
            <ListItem
              button
              className={classes.mobileItem}
              onClick={() => history.push('/swap')}>
              <ListItemText primary={'Swap'} />
            </ListItem>
            <ListItem
              button
              className={classes.mobileItem}
              onClick={() => history.push('/stake')}>
              <ListItemText primary={'Stake'} />
            </ListItem>
            <WalletAccount />
            <ListItem button className={classes.mobileItem} onClick={logout}>
              <ListItemText primary={'Log out'} />
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItem
              button
              className={classes.mobileItem}
              onClick={() => history.push('/')}>
              <ListItemText primary={'Home'} />
            </ListItem>

            <ListItem
              button
              className={classes.mobileItem}
              onClick={() => history.push('/buy-credit-card')}>
              <ListItemText primary={t('header.buy_crypto')} />
            </ListItem>
            <ListItem
              button
              className={classes.mobileItem}
              onClick={() => setOpenCompanyMb((pre) => !pre)}>
              <ListItemText>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  {t('header.company')} <ArrowDropDownIcon />
                </div>
              </ListItemText>
            </ListItem>
            {openCompanyMb && <CompanyMenuMb />}

            <ListItem
              button
              className={classes.mobileItem}
              onClick={handleOpenBlog}>
              <ListItemText primary={t('header.blog')} />
            </ListItem>
          </List>
        )}
        <ButtonMenuCustom
          onClick={handleLanguageIconClick}
          style={{marginLeft: 50}}
          className={classes.buttonLang2}>
          <Text variant="textHeader">
            {
              languagesLabel.find((language) => language.code === i18n.language)
                ?.text
            }
          </Text>
          <ArrowDropDownIcon className={classes.colorHeader2} />
        </ButtonMenuCustom>
        <StyledMenu
          id="language-menu"
          anchorEl={languageMenu}
          open={Boolean(languageMenu)}
          onClose={handleLanguageMenuClose}>
          {languagesLabel.map((language) => (
            <StyledMenuItem
              key={language.code}
              component="a"
              data-no-link="true"
              selected={i18n.language === language.code}
              onClick={() => handleChangeLanguage(language.code)}
              lang={language.code}
              hrefLang={language.code}>
              {language?.text}
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </div>
      {!auth && (
        <div className={classes.buttonFooter}>
          <ButtonMenuCustom
            color="inherit"
            style={{
              border: '1px solid #fff',
              borderRadius: 20,
              height: 32,
              color: '#fff',
              padding: '0 16px',
            }}
            onClick={() => history.push('/access-wallet')}>
            <Text variant="textHeader">{t('header.access_wallet')}</Text>
          </ButtonMenuCustom>
          <ButtonMenuCustom
            color="inherit"
            style={{
              backgroundColor: '#fff',
              color: '#041562',
              borderRadius: 20,
              height: 32,
              padding: '0 12px',
            }}
            className={classes.colorHeader}
            onClick={() => history.push('/create-wallet')}>
            <Text variant="textHeader">{t('header.create_wallet')}</Text>
          </ButtonMenuCustom>
        </div>
      )}
    </Drawer>
  );

  return (
    <AppBar
      color="inherit"
      position="fixed"
      elevation={0}
      style={{backgroundColor: scrollTop ? 'transparent' : '#fff'}}>
      <div className={classes.heightNav}>
        <Toolbar>
          <Grid container>
            <Grid
              item
              sm={3}
              md={3}
              style={{display: 'flex', alignItems: 'center'}}>
              {/* <Hidden smDown> */}
              <img
                onClick={() => history.push('/')}
                className={classes.logoImg}
                src={!scrollTop ? logo : logo1}
              />
              {/* </Hidden> */}
              {/* <Hidden mdUp>
                <img
                  onClick={() => history.push('/')}
                  className={classes.logoImg}
                  src={!scrollTop ? logo : logo1}
                />
              </Hidden> */}
            </Grid>

            <Hidden smDown>
              <Grid item sm={6} md={5} container justifyContent="center">
                {auth ? (
                  <div>
                    <ButtonMenuCustom
                      color="inherit"
                      className={
                        !scrollTop ? classes.colorHeader : classes.colorHeader2
                      }
                      onClick={() => history.push('/buy-credit-card')}>
                      <Text variant="textHeader">{t('header.buy_crypto')}</Text>
                    </ButtonMenuCustom>
                    {/* <ButtonMenuCustom
                      color="inherit"
                      className={
                        !scrollTop ? classes.colorHeader : classes.colorHeader2
                      }
                      onClick={() => history.push('/white-list')}>
                      <Text variant="textHeader">WhiteList</Text>
                    </ButtonMenuCustom> */}
                    <ButtonMenuCustom
                      color="inherit"
                      className={
                        !scrollTop ? classes.colorHeader : classes.colorHeader2
                      }
                      onClick={() => history.push('/token-default')}>
                      <Text variant="textHeader">Tokens</Text>
                    </ButtonMenuCustom>
                    <ButtonMenuCustom
                      color="inherit"
                      className={
                        !scrollTop ? classes.colorHeader : classes.colorHeader2
                      }
                      onClick={() => history.push('/sukh-bukh')}>
                      <Text variant="textHeader">UKH</Text>
                    </ButtonMenuCustom>
                    <ButtonMenuCustom
                      color="inherit"
                      className={
                        !scrollTop ? classes.colorHeader : classes.colorHeader2
                      }
                      onClick={() => history.push('/swap')}>
                      <Text variant="textHeader">Swap</Text>
                    </ButtonMenuCustom>

                    <ButtonMenuCustom
                      color="inherit"
                      className={
                        !scrollTop ? classes.colorHeader : classes.colorHeader2
                      }
                      onClick={() => history.push('/stake')}>
                      <Text variant="textHeader">Stake</Text>
                    </ButtonMenuCustom>
                  </div>
                ) : (
                  <div>
                    <ButtonMenuCustom
                      color="inherit"
                      className={
                        !scrollTop ? classes.colorHeader : classes.colorHeader2
                      }
                      onClick={() => history.push('/buy-credit-card')}>
                      <Text variant="textHeader">{t('header.buy_crypto')}</Text>
                    </ButtonMenuCustom>
                    <ButtonMenuCustom
                      color="inherit"
                      className={
                        !scrollTop ? classes.colorHeader : classes.colorHeader2
                      }
                      onClick={(e) => setCompanyAnchor(e.currentTarget)}>
                      <Text variant="textHeader">{t('header.company')}</Text>
                      <ArrowDropDownIcon />
                    </ButtonMenuCustom>
                    <ButtonMenuCustom
                      color="inherit"
                      className={
                        !scrollTop ? classes.colorHeader : classes.colorHeader2
                      }
                      onClick={handleOpenBlog}>
                      <Text variant="textHeader">{t('header.blog')}</Text>
                    </ButtonMenuCustom>
                  </div>
                )}
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item sm={4} md={4} container justifyContent="flex-end">
                <div>
                  <ButtonMenuCustom
                    onClick={handleLanguageIconClick}
                    className={
                      !scrollTop ? classes.buttonLang : classes.buttonLang2
                    }>
                    <Text variant="textHeader">
                      {
                        languagesLabel.find(
                          (language) => language.code === i18n.language,
                        )?.text
                      }
                    </Text>
                    <ArrowDropDownIcon
                      className={
                        !scrollTop ? classes.colorHeader : classes.colorHeader2
                      }
                    />
                  </ButtonMenuCustom>

                  <StyledMenu
                    id="language-menu"
                    anchorEl={languageMenu}
                    open={Boolean(languageMenu)}
                    onClose={handleLanguageMenuClose}>
                    {languagesLabel.map((language) => (
                      <StyledMenuItem
                        key={language.code}
                        component="a"
                        data-no-link="true"
                        selected={i18n.language === language.code}
                        onClick={() => handleChangeLanguage(language.code)}
                        lang={language.code}
                        hrefLang={language.code}>
                        {language?.text}
                      </StyledMenuItem>
                    ))}
                  </StyledMenu>
                  {auth ? (
                    <WalletAccount />
                  ) : (
                    <>
                      <ButtonMenuCustom
                        color="inherit"
                        className={
                          !scrollTop
                            ? classes.colorHeader
                            : classes.colorHeader2
                        }
                        onClick={() => history.push('/access-wallet')}>
                        <Text variant="textHeader">
                          {t('header.access_wallet')}
                        </Text>
                      </ButtonMenuCustom>
                      <ButtonMenuCustom
                        color="inherit"
                        style={{
                          backgroundColor: scrollTop ? '#fff' : '#463A6D',
                          borderRadius: 20,
                          height: 32,
                          padding: '0 12px',
                        }}
                        className={
                          !scrollTop
                            ? classes.colorHeader2
                            : classes.colorHeader
                        }
                        onClick={() => history.push('/create-wallet')}>
                        <Text variant="textHeader">
                          {t('header.create_wallet')}
                        </Text>
                      </ButtonMenuCustom>
                    </>
                  )}
                </div>
              </Grid>
            </Hidden>

            {/* <Hidden smDown>{auth && <WalletAccount />}</Hidden> */}

            <Hidden mdUp>
              <IconButton
                onClick={handleMobileMenuOpen}
                color="inherit"
                style={{position: 'absolute', top: 8, right: 0}}>
                <MenuIcon
                  className={
                    !scrollTop ? classes.colorHeader : classes.colorHeader2
                  }
                />
              </IconButton>
            </Hidden>
            {renderMobileMenu}
            {auth && (
              <ModalAddress openModal={openModal} handleClose={handleClose} />
            )}
            <CompanyMenu
              anchorEl={companyAnchor}
              open={Boolean(companyAnchor)}
              handleClose={handleCompanyMenuClose}
            />
          </Grid>
        </Toolbar>
      </div>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  bigBox: {
    boxShadow: '0 2px 13px rgb(0 0 0 / 10%)',
    backgroundColor: '#fff',
    zIndex: 3,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menuMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoImg: {
    width: 300,
    cursor: 'pointer',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 250,
      height: 21.6,
    },
  },
  colorHeader: {
    color: '#463A6D',
  },
  colorHeader2: {
    color: '#fff',
  },
  list: {
    width: '100%',
  },
  drawerPaper: {
    width: '100%',
    background: '#092974',
    color: '#fff',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  mobileItem: {
    padding: '10px 50px',
  },

  heightNav: {
    height: 80,
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      height: 60,
    },
    padding: '0 32px',
  },

  buttonLang: {
    color: '#463A6D',
    border: '1px solid #463A6D',
    borderRadius: 20,
    height: 32,
    // width: 60,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16,
      width: 80,
    },
  },
  buttonLang2: {
    color: '#fff',
    border: '1px solid #fff',
    borderRadius: 20,
    height: 32,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      width: 80,
    },
  },
  buttonFooter: {
    position: 'absolute',
    bottom: 0,
    paddingBottom: 32,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #041562',
    // paddingLeft: 6,
    // paddingRight: 25,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    width: '100%',
    padding: '10px 60px 10px 20px',
    '&:focus': {
      // backgroundColor: '#fff',
      color: '#05c0a5',
    },
    // '&:hover': {
    //   // color: '#fff',
    //   backgroundColor: '#fff !important',
    // },
  },
}))(MenuItem);

export default MainNavbar;
