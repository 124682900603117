// import MetamaskIcon from "assets/images/wallet/metamask.svg";

export const MOCK = {
  dataSource: [
    {
      key: '1',
      name: 'John Brown',
      price: 0.000067,
      rate: 0.015,
    },
    {
      key: '2',
      name: 'Jim Green',
      price: 0.000032,
      rate: -1,
    },
    {
      key: '3',
      name: 'Joe Black',
      price: 0.000012,
      rate: 2,
    },
    {
      key: '4',
      name: 'John Brown',
      price: 0.000036,
      rate: 2.56,
    },
    {
      key: '5',
      name: 'Jim Green',
      price: 0.0000023,
      rate: -1.3,
    },
    {
      key: '6',
      name: 'Joe Black',
      price: 0.00056,
      rate: 4,
    },
    {
      key: '7',
      name: 'John Brown',
      price: 0.0000045,
      rate: -1.5,
    },
    {
      key: '8',
      name: 'Jim Green',
      price: 0.0000035,
      rate: 2,
    },
    {
      key: '9',
      name: 'Joe Black',
      price: 0.000045,
      rate: 3,
    },
    {
      key: '1' + 1,
      name: 'John Brown',
      price: 0.000067,
      rate: 0.015,
    },
    {
      key: '2' + 1,
      name: 'Jim Green',
      price: 0.000032,
      rate: -1,
    },
    {
      key: '3' + 1,
      name: 'Joe Black',
      price: 0.000012,
      rate: 2,
    },
    {
      key: '4' + 1,
      name: 'John Brown',
      price: 0.000036,
      rate: 2.56,
    },
    {
      key: '5' + 1,
      name: 'Jim Green',
      price: 0.0000023,
      rate: -1.3,
    },
    {
      key: '6' + 1,
      name: 'Joe Black',
      price: 0.00056,
      rate: 4,
    },
    {
      key: '7' + 1,
      name: 'John Brown',
      price: 0.0000045,
      rate: -1.5,
    },
    {
      key: '8' + 1,
      name: 'Jim Green',
      price: 0.0000035,
      rate: 2,
    },
    {
      key: '9' + 1,
      name: 'Joe Black',
      price: 0.000045,
      rate: 3,
    },
  ],
};

export const THEME_SETTING = {
  APP_BAR_HEIGHT: 90,
};

export const CONNECTORS = {
  portis: {dAppId: 'my-dapp-id-123-xyz'},
  walletconnect: {rpcUrl: process.env.REACT_APP_RPC_URL},
};

export const CONNECT_STATUS = {
  DISCONNECTED: 'disconnected',
  CONNECTING: 'connecting',
  CONNECTED: 'connected',
  ERROR: 'error',
};

export const ACTION = {
  BUY: 0,
  SELL: 1,
};

export const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID) || 1;

export const TRANSACTION_STATUS = {
  PENDING: 0,
  SUCCESS: 1,
  ERROR: 2,
};

export const COEFFICIENT_GAS_PRICE = 2;

export const MAX_APPROVE_KGR = '10000000000000000000000000'; // 10000000 KGR

export const MAX_LENGTH_FLOAT = 8;

export const IS_DEFAULT = {
  ACTIVE: 1,
  UNACTIVE: 0,
};

export const MINIMUM_BUY = {
  KGR: 5000,
  HLN: 500,
};
export const link_apple_download =
  'https://apps.apple.com/app/fgraph/id1572609905';
export const link_google_download =
  'https://play.google.com/store/apps/details?id=io.fgraph.shinobiwallet';

export const TYPE_TOKEN = {
  TOKEN: 'token',
  DEFI: 'Defi',
  ERC20: 'erc20',
  BTC: 'bitcoin',
  BSC: 'bsc',
  MATIC: 'matic',
};
export const TYPE_CHAIN = {
  ETH: 'ETH',
  BSC: 'BSC',
  MATIC: 'MATIC',
};
export const BTC_NETWORK = {
  TESTNET: 'testnet',
  LIVENET: 'livenet',
  TEST3: 'test3',
  MAIN: 'main',
};
export const ETHER_DETAIL =
  process.env.REACT_APP_IS_PRODUCTION === 'true'
    ? 'https://etherscan.io'
    : 'https://ropsten.etherscan.io';
export const BSC_DETAIL =
  process.env.REACT_APP_IS_PRODUCTION === 'true'
    ? 'https://bscscan.com'
    : 'https://testnet.bscscan.com';
export const MATIC_DETAIL =
  process.env.REACT_APP_IS_PRODUCTION === 'true'
    ? 'https://polygonscan.com'
    : 'https://mumbai.polygonscan.com';
export const LANGUAGE_I18N = {
  ENG: 'en-US',
  JAP: 'ja-JP',
  HIN: 'hi-IN',
  KOR: 'ko-KR',
  ESP: 'es-ES',
};
export const TYPE_CONTACT = [
  // {
  //   label: 'Choose a reason you’re contacting',
  //   value: '',
  // },
  {
    label: 'Product',
    value: 'Product',
  },
  {
    label: 'Help & Support',
    value: 'Help & Support',
  },
  {
    label: 'Media',
    value: 'Media',
  },
  {
    label: 'Partnership',
    value: 'Partnership',
  },
];
