const navItems = [
  {
    id: 'privacy-1',
    text: 'Privacy policy',
    type: 'parents',
  },
  {
    id: 'privacy-2',
    text: 'Interpretation & Definitions',
    type: 'parents',
  },
  {
    id: 'privacy-3',
    text: 'Interpretation',
    type: 'children',
  },

  {
    id: 'privacy-4',
    text: 'Definition',
    type: 'children',
  },

  {
    id: 'privacy-5',
    text: 'Collecting & using your personal data',
    type: 'parents',
  },

  {
    id: 'privacy-6',
    text: 'Type of data collected',
    type: 'children',
  },

  {
    id: 'privacy-7',
    text: 'Use of your personal data',
    type: 'children',
  },

  {
    id: 'privacy-8',
    text: 'Retention of your personal data',
    type: 'children',
  },

  {
    id: 'privacy-9',
    text: 'Transfer of your personal data',
    type: 'children',
  },

  {
    id: 'privacy-10',
    text: 'Disclosure of your personal data',
    type: 'children',
  },

  {
    id: 'privacy-11',
    text: 'Security of your personal data',
    type: 'children',
  },

  {
    id: 'privacy-12',
    text: 'Children’s privacy',
    type: 'parents',
  },

  {
    id: 'privacy-13',
    text: 'Links to other websites',
    type: 'parents',
  },

  {
    id: 'privacy-14',
    text: 'Changes to this privacy policy',
    type: 'parents',
  },

  {
    id: 'privacy-15',
    text: 'Contact us',
    type: 'parents',
  },
];

export {navItems};
