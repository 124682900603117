import {Cushion} from 'components';

import {contentItems} from './ContentItems';
import {navItems} from './NavItems';
import {PageWithTableOfContent} from 'containers/TermsOfUse/PageWithTableOfContent';

const PrivacyPolicy = () => {
  return (
    <>
      <Cushion />
      <PageWithTableOfContent
        pageTitle="Privacy policy"
        navItems={navItems}
        contentItems={contentItems}
      />
    </>
  );
};

export default PrivacyPolicy;
