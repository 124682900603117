import {React} from 'react';
import {Grid, Menu, SvgIcon, Typography, useTheme} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {ReactComponent as aboutIcon} from 'assets/images/svg/company/about.svg';
import {ReactComponent as privacyIcon} from 'assets/images/svg/company/privacy.svg';
import {ReactComponent as partnerIcon} from 'assets/images/svg/company/partner.svg';
import {ReactComponent as contactIcon} from 'assets/images/svg/company/contact.svg';
import {ReactComponent as cookieIcon} from 'assets/images/svg/company/cookie.svg';
import {ReactComponent as faqIcon} from 'assets/images/svg/company/faq.svg';
import {ReactComponent as careerIcon} from 'assets/images/svg/company/career.svg';
import {ReactComponent as termIcon} from 'assets/images/svg/company/term.svg';
import {ReactComponent as liteIcon} from 'assets/images/svg/company/lite-paper.svg';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const link_wp_jp =
  'https://drive.google.com/file/d/1xFzPMIdPYJW1-NY2bLST91TquiYhPFnR/view?usp=sharing';
const link_wp_en =
  'https://drive.google.com/file/d/1DsjtFiV_HaWCMeZgYa4YqE9CbpIk_lm-/view?usp=sharing';

function CompanyMenu({open, anchorEl, handleClose}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const {t, i18n} = useTranslation();
  const data = [
    {
      icon: aboutIcon,
      link: '/about-us',
      text: t('footer.about'),
    },
    // {
    //   icon: privacyIcon,
    //   link: '/privacy-policy',
    //   text: t('footer.privacy'),
    // },
    {
      icon: partnerIcon,
      link: '/partners',
      text: t('footer.partners'),
    },
    {
      icon: contactIcon,
      link: '/contact',
      text: t('footer.contact'),
    },
    // {
    //   icon: cookieIcon,
    //   link: '/cookie',
    //   text: t('footer.cookie'),
    // },
    {
      icon: faqIcon,
      link: '/help-center',
      text: t('footer.help'),
    },
    {
      icon: careerIcon,
      link: '/careers',
      text: t('footer.career'),
    },
    // {
    //   icon: termIcon,
    //   link: '/terms-of-use',
    //   text: t('footer.term'),
    // },
    {
      icon: liteIcon,
      type: 1,
      link: i18n.language == 'ja-JP' ? link_wp_jp : link_wp_en,
      text: t('footer.white_paper'),
    },
  ];
  const handleOpenLink = (item) => {
    if (item?.type == 1) {
      window.open(item.link);
    } else {
      history.push(item.link);
    }
  };
  return (
    <StyledMenu
      id="language-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}>
      <Grid container className={classes.root}>
        {data.map((item) => (
          <Grid
            key={item.link}
            item
            xs={4}
            onClick={() => handleOpenLink(item)}
            style={{
              display: 'flex',
              justifyContent: 'left',
              paddingLeft: 10,
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <SvgIcon component={item.icon} viewBox="0 0 24 24" />
            <Typography className={classes.text}>{item.text}</Typography>
          </Grid>
        ))}
      </Grid>
    </StyledMenu>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    borderRadius: 10,
    height: 200,
    width: 500,
    boxShadow: '25px 25px 50px rgba(0, 0, 0, 0.05)',
  },
  text: {
    fontSize: 14,
  },
}));
const StyledMenu = withStyles({
  paper: {
    // border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    // elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    MenuListProps={{disablePadding: true}}
    {...props}
  />
));
export default CompanyMenu;
