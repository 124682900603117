import {React, useState} from 'react';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import logo from 'assets/images/LOGO_P.png';
import {useFormik} from 'formik';
import {useHistory} from 'react-router-dom';
import {useAPI} from 'api/api';
import * as Yup from 'yup';
import {Text} from 'App';
import {useTranslation} from 'react-i18next';
import {RoundedButton} from 'components';

function FormPartner() {
  const classes = useStyles();
  const theme = useTheme();
  const api = useAPI();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const maschesMd = useMediaQuery(theme.breakpoints.up('sm'));
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      company_name: '',
      company_web: '',
      email: '',
      telegram: '',
      description: '',
    },
    validationSchema: Yup.object().shape({
      company_name: Yup.string().required('Company Name is required'),
      company_web: Yup.string().required('Company website is required'),
      email: Yup.string().email('Invalid email').required('Email is Required'),
      description: Yup.string().required('Message is required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const res = await api.fetcher('post', 'inquiry', values);
        if (res) {
          history.push('/thank-you');
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        formik.setErrors(error.data?.errors);
        setLoading(false);
      }
    },
  });
  return (
    <Grid container className={classes.root}>
      <Grid container justifyContent="center" item xs={12} alignItems="center">
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={10}
          spacing={maschesMd ? 3 : 0}
          className={classes.paper}>
          <Grid item xs={12} style={{textAlign: 'center', marginBottom: 48}}>
            <Text variant="titlePass">{t('homepage.be_come_partner')}</Text>
          </Grid>

          <Grid item xs={12} sm={12} md={6} className={classes.rowInput}>
            <Typography className={classes.titleInput}>
              {t('form_partner.company_name')}
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              name="company_name"
              placeholder={t('form_partner.input_name')}
              error={
                !!(
                  formik?.errors?.company_name && formik?.touched?.company_name
                )
              }
              helperText={
                formik?.touched?.company_name && formik?.errors?.company_name
              }
              onBlur={formik.handleBlur}
              value={formik?.values?.company_name}
              onChange={formik.handleChange}
              size={matches ? 'small' : 'medium'}
              className={classes.inputField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.rowInput}>
            <Typography className={classes.titleInput}>
              {t('form_partner.company_web')}
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              name="company_web"
              placeholder={t('form_partner.input_web')}
              error={
                !!(formik?.errors?.company_web && formik?.touched?.company_web)
              }
              helperText={
                formik?.touched?.company_web && formik?.errors?.company_web
              }
              onBlur={formik.handleBlur}
              value={formik?.values?.company_web}
              onChange={formik.handleChange}
              size={matches ? 'small' : 'medium'}
              className={classes.inputField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.rowInput}>
            <Typography className={classes.titleInput}>
              {t('form_partner.email')}
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              name="email"
              placeholder={t('form_partner.input_email')}
              error={!!(formik?.errors?.email && formik?.touched?.email)}
              helperText={formik?.touched?.email && formik?.errors?.email}
              onBlur={formik.handleBlur}
              value={formik?.values?.email}
              onChange={formik.handleChange}
              size={matches ? 'small' : 'medium'}
              className={classes.inputField}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className={classes.rowInput}>
            <Typography className={classes.titleInput}>
              {t('form_partner.telegram')}
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              name="telegram"
              placeholder={t('form_partner.input_tele')}
              error={!!(formik?.errors?.telegram && formik?.touched?.telegram)}
              helperText={formik?.touched?.telegram && formik?.errors?.telegram}
              onBlur={formik.handleBlur}
              value={formik?.values?.telegram}
              onChange={formik.handleChange}
              size={matches ? 'small' : 'medium'}
              className={classes.inputField}
            />
          </Grid>
          <Grid item xs={12} className={classes.rowInput}>
            <Typography className={classes.titleInput}>
              {t('form_partner.project')}
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              minRows={4}
              maxRows={4}
              name="description"
              placeholder={t('form_partner.input_project')}
              error={
                !!(formik?.errors?.description && formik?.touched?.description)
              }
              helperText={
                formik?.touched?.description && formik?.errors?.description
              }
              onBlur={formik.handleBlur}
              value={formik?.values?.description}
              onChange={formik.handleChange}
              size={matches ? 'small' : 'medium'}
              className={classes.inputField}
            />
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <RoundedButton
              isSmall={false}
              hasIcon
              variant="contained"
              style={{padding: '6px 60px', marginTop: 54}}
              onClick={formik.handleSubmit}>
              {t('form_partner.submit')}
            </RoundedButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F2F5FF',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0',
      background: '#F2F5FF',
    },
    maxHeight: '100%',
  },
  paper: {
    background: '#F2F5FF',
    margin: 0,
  },
  titleInput: {
    marginBottom: 8,
  },
  rowInput: {
    marginBottom: 16,
  },
  inputField: {
    background: '#fff',
  },
}));
export default FormPartner;
