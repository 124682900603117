// store.js
import React, {createContext, useReducer} from 'react';

const initialState = {
  wallet: [],
  noReloadPage: false,
};
const storeData = createContext(initialState);
const {Provider} = storeData;

const StoreProvider = ({children}) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_DATA':
        return {...state, dataList: action.payload};
      case 'SET_NO_RELOAD_PAGE':
        return {...state, noReloadPage: action.payload};
      case 'SET_WALLET_UPDATE':
        return {
          ...state,
          wallet: action.payload,
        };
      default:
        throw new Error();
    }
  }, initialState);

  return <Provider value={{state, dispatch}}>{children}</Provider>;
};

export {storeData, StoreProvider};
