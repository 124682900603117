import {IS_DEFAULT} from 'common/constants';
import React, {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {AuthContext} from 'store/AuthProvider';

//-------------------------------------

const ProtectedRoute = React.memo(({children, ...rest}) => {
  const {wallet} = useContext(AuthContext);
  const index = wallet.findIndex(
    (wal) => wal.is_default == IS_DEFAULT['ACTIVE'],
  );
  return (
    <Route
      {...rest}
      render={({location}) =>
        index >= 0 ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/access-wallet',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
});

export default ProtectedRoute;
