import {React} from 'react';
import {Grid, Menu, SvgIcon, Typography, useTheme} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {ReactComponent as aboutIcon} from 'assets/images/svg/company/about-white.svg';
import {ReactComponent as privacyIcon} from 'assets/images/svg/company/privacy-white.svg';
import {ReactComponent as partnerIcon} from 'assets/images/svg/company/partner-white.svg';
import {ReactComponent as contactIcon} from 'assets/images/svg/company/contact-white.svg';
import {ReactComponent as cookieIcon} from 'assets/images/svg/company/cookie-white.svg';
import {ReactComponent as faqIcon} from 'assets/images/svg/company/faq-white.svg';
import {ReactComponent as careerIcon} from 'assets/images/svg/company/career-white.svg';
import {ReactComponent as termIcon} from 'assets/images/svg/company/term-white.svg';
import {ReactComponent as liteIcon} from 'assets/images/svg/company/lite-paper-white.svg';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const link_wp_jp =
  'https://drive.google.com/file/d/1xFzPMIdPYJW1-NY2bLST91TquiYhPFnR/view?usp=sharing';
const link_wp_en =
  'https://drive.google.com/file/d/1DsjtFiV_HaWCMeZgYa4YqE9CbpIk_lm-/view?usp=sharing';

function CompanyMenuMb({open, handleClose}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const {t, i18n} = useTranslation();
  const data = [
    {
      icon: aboutIcon,
      link: '/about-us',
      text: t('footer.about'),
    },
    // {
    //   icon: privacyIcon,
    //   link: '/privacy-policy',
    //   text: t('footer.privacy'),
    // },
    {
      icon: partnerIcon,
      link: '/partners',
      text: t('footer.partners'),
    },
    {
      icon: contactIcon,
      link: '/contact',
      text: t('footer.contact'),
    },
    // {
    //   icon: cookieIcon,
    //   link: '/cookie',
    //   text: t('footer.cookie'),
    // },
    {
      icon: faqIcon,
      link: '/help-center',
      text: t('footer.help'),
    },
    {
      icon: careerIcon,
      link: '/careers',
      text: t('footer.career'),
    },
    // {
    //   icon: termIcon,
    //   link: '/terms-of-use',
    //   text: t('footer.term'),
    // },
    {
      icon: liteIcon,
      type: 1,
      link: i18n.language == 'ja-JP' ? link_wp_jp : link_wp_en,
      text: t('footer.white_paper'),
    },
  ];
  const handleOpenLink = (item) => {
    if (item?.type == 1) {
      window.open(item.link);
    } else {
      history.push(item.link);
    }
  };
  return (
    <Grid container className={classes.root}>
      {data.map((item) => (
        <Grid
          key={item.link}
          item
          xs={6}
          onClick={() => handleOpenLink(item)}
          style={{
            display: 'flex',
            justifyContent: 'left',
            paddingLeft: 50,
            alignItems: 'center',
            cursor: 'pointer',
            marginTop: 19,
          }}>
          <SvgIcon
            component={item.icon}
            viewBox="0 0 24 24"
            style={{color: '#fff'}}
          />
          <Typography className={classes.text}>{item.text}</Typography>
        </Grid>
      ))}
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    background: 'rgba(255, 255, 255, 0.05)',
    // height: 200,
    width: '100%',
    boxShadow: '25px 25px 50px rgba(0, 0, 0, 0.05)',
    paddingBottom: 19,
  },
  text: {
    fontSize: 14,
  },
}));
export default CompanyMenuMb;
