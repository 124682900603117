import {ethers} from 'ethers';
import {getMainnetUrl} from './utils';
import {Contract} from '@ethersproject/contracts';
import abiErc20 from './../ERC20/IERC20.json';

const {utils, Wallet} = ethers;

const rpcUrl = getMainnetUrl();
const PROVIDER = new ethers.providers.JsonRpcProvider(rpcUrl);

// const network = 'mainnet';
// let network = (process.env.NODE_ENV === 'production') ?
//     { name: 'mainnet', ensAddress: '0x314159265dd8dbb310642f98f50c066173c1259b', chainId: 1 } :
//     { name: 'rinkeby', ensAddress: '0xe7410170f87102df0055eb195163a03b7f2bff4a', chainId: 4 };

// const PROVIDER = ethers.getDefaultProvider(network);

export function loadWalletFromMnemonics(mnemonics) {
  if (!(mnemonics instanceof Array) && typeof mnemonics !== 'string') {
    throw new Error('invalid mnemonic');
  } else if (mnemonics instanceof Array) {
    mnemonics = mnemonics.join(' ');
  }

  // const {privateKey} = Wallet.fromMnemonic(mnemonics);

  return Wallet.fromMnemonic(mnemonics);
}

export function loadPrivateKey(mnemonics) {
  if (!(mnemonics instanceof Array) && typeof mnemonics !== 'string') {
    throw new Error('invalid mnemonic');
  } else if (mnemonics instanceof Array) {
    mnemonics = mnemonics.join(' ');
  }

  const {privateKey} = Wallet.fromMnemonic(mnemonics);
  return privateKey;
}

export function loadWalletFromPrivateKey(pk) {
  try {
    if (pk.indexOf('0x') !== 0) {
      pk = `0x${pk}`;
    }
    return new Wallet(pk, PROVIDER);
  } catch (e) {
    throw new Error('invalid private key');
  }
}

export function formatBalance(balance) {
  return utils.formatEther(balance);
}

export function reduceBigNumbers(items) {
  if (!(items instanceof Array)) {
    throw new Error('The input is not an Array');
  }
  return items.reduce((prev, next) => prev.add(next), utils.bigNumberify('0'));
}

export function calculateFee({gasUsed, gasPrice}) {
  return gasUsed * Number(formatBalance(gasPrice));
}

export function estimateFee({gasLimit, gasPrice}) {
  return utils.bigNumberify(String(gasLimit)).mul(String(gasPrice));
}

export async function getBalanceByToken(item, address, setLoadBalance = null) {
  if (typeof setLoadBalance === 'function') {
    setLoadBalance(true);
  }
  let balance = 0;
  const rpcUrl = getMainnetUrl();
  const PROVIDER = new ethers.providers.JsonRpcProvider(rpcUrl);
  if (item.symbol === 'ETH') {
    const balanceETH = await PROVIDER.getBalance(address);
    balance = ethers.utils.formatEther(balanceETH);
    if (balance === '0.0') {
      balance = 0;
    }
  } else {
    try {
      const contract = new Contract(item?.address, abiErc20, PROVIDER);
      const balanceToken = await contract.balanceOf(address);

      balance = ethers.utils.formatUnits(balanceToken, item?.decimals);
      if (balance === '0.0') {
        balance = 0;
      }
    } catch (e) {
      balance = 0;
      // console.log(e);
    }
  }
  if (typeof setLoadBalance === 'function') {
    setLoadBalance(false);
  }
  return balance;
}
