import React, {useState, useEffect, useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Modal, TextField, InputAdornment} from '@material-ui/core';
import {AuthContext} from 'store/AuthProvider';
import CloseIcon from '@material-ui/icons/Close';
import {IS_DEFAULT} from 'common/constants';
import {ButtonClose} from 'components/StyledButtons';
import {useTranslation} from 'react-i18next';

export default function ModalMnemonic({openModal, handleClose}) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const {wallet} = useContext(AuthContext);
  const [mnemonics, setMnemonics] = useState();
  const [open, setOpen] = useState(false);
  const {t} = useTranslation();
  useEffect(() => {
    if (wallet.length > 0) {
      const index = wallet.findIndex(
        (val) => val.is_default == IS_DEFAULT['ACTIVE'],
      );
      if (index >= 0) {
        if (wallet[index].mnemonics) {
          setMnemonics(wallet[index].mnemonics.split(' '));
        }
      }
    }
  }, [wallet]);
  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);
  return (
    <React.Fragment>
      <Modal open={openModal} onClose={handleClose}>
        <div style={modalStyle} className="contentModal max500">
          <div className="header">
            <div className="modalTitle">Wallet Mnemonics</div>
            <div onClick={handleClose} style={{cursor: 'pointer'}}>
              <CloseIcon style={{color: '#fff'}} />
            </div>
          </div>
          <div className={classes.content}>
            <Grid container spacing={3}>
              {mnemonics &&
                mnemonics.map((m, i) => (
                  <Grid item md={4} xs={4} key={i}>
                    <TextField
                      className={classes.contentMnemonic}
                      label=""
                      color={'blue'}
                      value={m}
                      disabled
                      onChange={(e) => handleChangeMnemonic(e.target.value, i)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {i + 1}. {'  '}
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                        classes: {
                          root: classes.colorTextField,
                          disabled: classes.disabled,
                        },
                      }}
                    />
                  </Grid>
                ))}
            </Grid>

            <ButtonClose variant="outlined" onClick={handleClose}>
              {t('close')}
            </ButtonClose>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 100,
  },

  contentMnemonic: {
    borderBottom: '1px solid #e0e0e0',
    color: '#999',
    display: 'flex',
    padding: '10px 10px',
  },
  content: {
    textAlign: 'center',
    margin: '40px 0',
    padding: '0 10px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  colorTextField: {
    '&$disabled': {
      color: '#000',
    },
  },
  disabled: {},
}));

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '95%',
  };
}
