import {styled, Typography} from '@mui/material';
import {blue} from 'styles/color';

const HeaderText = ({children, ...props}) => {
  return <StyledHeader {...props}>{children}</StyledHeader>;
};

const StyledHeader = styled(Typography, {
  shouldForwardProp: (prop) => !['isSmall', 'isGiant'].includes(prop),
})(({theme, isSmall, isGiant = false}) => ({
  ...(!isSmall && {
    fontSize: theme.spacing(4.5),
    lineHeight: theme.spacing(5.4),
  }),

  ...(isSmall && {
    fontSize: theme.spacing(3),
    lineHeight: theme.spacing(3.6),
  }),

  ...(isGiant && {
    fontSize: theme.spacing(8),
    lineHeight: theme.spacing(9.6),
  }),

  fontWeight: 500,
  color: blue[800],
  fontFamily: ['Noto Sans', 'sans-serif'].join(', '),

  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    ...(isGiant && {
      fontSize: theme.spacing(6),
      lineHeight: theme.spacing(7.6),
    }),
  },
}));

export {HeaderText};
