import React, {useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  styled,
} from '@material-ui/core/styles';
import ReactNotification from 'react-notifications-component';
import 'react-notifications/lib/notifications.css';
import {useTranslation} from 'react-i18next';
import MainRouter from './routes/MainRouter';
import {languagesLabel} from './common/i18n';
import {NotificationContainer} from 'react-notifications';
import {StoreProvider} from 'store/StoreProvider';
import AuthProvider from 'store/AuthProvider';
import {compose, css, style, typography} from '@material-ui/system';
import {Typography} from '@material-ui/core';
import {grey} from 'styles/color';
import {QueryClient, QueryClientProvider} from 'react-query';
import {createTheme as createThemNew} from '@mui/material';

//-------------------------------------

const variant = style({
  prop: 'variant',
  cssProperty: false,
  themeKey: 'typography',
});

export const Text = styled(Typography)(css(compose(variant, typography)));
let theme = createTheme({
  typography: {
    fontFamily: ['Noto Sans', 'sans-serif'].join(', '),
  },
});
theme = createTheme(theme, {
  typography: {
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 500,
    },
    namecoin: {
      fontWeight: 600,
      fontSize: '16px',
      color: '#041562',
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
        fontWeight: 400,
      },
    },

    titleFunction: {
      fontWeight: 600,
      fontSize: '1.5vw',
      color: '#041562',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5vw',
        fontWeight: 600,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
        fontWeight: 600,
      },
    },
    textHeader: {
      fontSize: 16,
      fontWeight: 400,
    },
    symbolcoin: {
      fontWeight: 600,
      fontSize: '14px',
      color: '#8c8c8c',
      [theme.breakpoints.down('xs')]: {
        fontSize: '9px',
        fontWeight: 400,
      },
    },
    h21: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '35px',
    },
    h3regular: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: '20px',
    },
    h5regular: {
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 400,
    },
    h6regular: {
      fontSize: 22,
      lineHeight: '26.4px',
      fontWeight: 400,
    },
    description1: {
      fontSize: 14,
      lineHeight: '16.8px',
      fontWeight: 400,
    },
    description2: {
      fontSize: 18,
      lineHeight: '21.6px',
      fontWeight: 400,
    },
    description3: {
      fontSize: 18,
      lineHeight: '35px',
      fontWeight: 500,
    },
    // fontFamily: ['Asap', 'sans-serif'].join(', '),
    titleFooter: {
      fontSize: 64,
      fontWeight: 600,
      color: '#041562',
      [theme.breakpoints.down('sm')]: {
        fontSize: 40,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 32,
      },
    },
    textFooter: {
      fontSize: 22,
      color: '#8E8E8E',
      lineHeight: '26.4px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
    textFooter2: {
      fontSize: 18,
      color: '#8E8E8E',
      lineHeight: '35px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    },
    titlePass: {
      fontSize: 36,
      fontWeight: 600,
      color: '#041562',
      [theme.breakpoints.down('sm')]: {
        fontSize: 30,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 24,
      },
    },
    nameTeam: {
      fontSize: 24,
      fontWeight: 600,
      color: '#041562',
    },
    jobTeam: {
      fontSize: 18,
      color: '#8E8E8E',
      lineHeight: '26.4px',
    },
    titleCookie: {
      fontSize: 20,
      fontWeight: 600,
    },
    titleCareer: {
      fontSize: 24,
      fontWeight: 400,
    },
    desCareer: {
      fontSize: 16,
      fontWeight: 400,
      color: '#8E8E8E',
      marginBottom: 51,
    },
    textAbout: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '24px',
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
        lineHeight: '14.4px',
      },
    },
    titlePhrase: {
      fontSize: 24,
      fontWeight: 600,
      color: '#041562',
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
  },
  palette: {
    color: {
      blue: '#05c0a5',
    },
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    grey: {
      900: grey[900],
      600: grey[600],
      550: grey[550],
      500: grey[500],
      450: grey[450],
      300: grey[300],
      200: grey[200],
      100: grey[100],
      50: grey[50],
    },
  },
});

theme = responsiveFontSizes(theme);

const App = () => {
  const queryClient = new QueryClient();
  const {i18n} = useTranslation();

  useEffect(() => {
    const trans = languagesLabel.map((el) => el.code);
    if (trans.findIndex((el) => el === i18n.language) === -1) {
      i18n.changeLanguage('en-US');
    }
  }, [i18n]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ReactNotification />
      <StoreProvider>
        <NotificationContainer />
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <MainRouter />
          </QueryClientProvider>
        </AuthProvider>
      </StoreProvider>
    </ThemeProvider>
  );
};

export default App;
