import React, {useEffect, useMemo, useState} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Button,
  Typography,
  Checkbox,
  Link,
  SvgIcon,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Text} from 'App';
import {RoundedButton} from 'components';
import shinobiApp from 'assets/images/home/shinobi-app.png';
import createWallet from 'assets/images/create-wallet.png';
import CallMadeIcon from '@mui/icons-material/CallMade';
import {ethers} from 'ethers';
import {arraysIdentical, checkForDuplicates, shuffle} from 'common/utils';
import {ReactComponent as copyIcon} from 'assets/images/svg/copy.svg';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {NotificationManager} from 'react-notifications';
import {useHistory} from 'react-router-dom';

export default function ConfirmPhrase({
  mnemonics,
  mnemonicsShuffle,
  handleSavedPhrase,
}) {
  const useStyles = makeStyles((theme) => ({
    warning: {
      backgroundColor: '#fbe7e7',
      padding: '24px 40px',
    },
    checkBox: {
      display: 'flex',
      marginTop: 29,
      marginBottom: 37,
      alignItems: 'center',
      justifyContent: 'center',
    },
    itemPhrases: {
      border: '1px solid #d2d2d2',
      padding: 8,
      borderRadius: 6,
      textAlign: 'center',
    },
    mnemonicsSelected: {
      backgroundColor: '#f1f1f1',
      padding: '20px 10px',
      minHeight: 100,
      borderRadius: 10,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    sequenceSelected: {
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      // minHeight: 150,
    },

    mnemonic: {
      border: '1px solid #e0e0e0',
      padding: '8px 16px',
      marginRight: 12,
      marginTop: 10,
      borderRadius: 2,
      [theme.breakpoints.down('xs')]: {
        padding: '8px 10px',
      },
    },

    wordButton: {
      padding: '8px 16px',
      [theme.breakpoints.down('xs')]: {
        padding: '8px 4px',
      },
    },
  }));

  const classes = useStyles();
  const {t} = useTranslation();
  const [selectable, setSelectable] = useState(mnemonicsShuffle);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(false);
  const history = useHistory();
  const onPressMnemonic = (mnemonic, isSelected) => {
    if (isSelected) {
      if (!selected.includes(mnemonic)) {
        setSelectable(selectable.filter((m) => m !== mnemonic));
        setSelected(selected.concat([mnemonic]));
      }
    } else {
      setSelectable(selectable.concat([mnemonic]));
      setSelected(selected.filter((m) => m !== mnemonic));
    }
  };
  useEffect(() => {
    let res = !arraysIdentical(selected, mnemonics);
    setError(res);
  }, [selected]);
  const handleComplete = () => {
    try {
      if (mnemonics.length !== selected.length || error) {
        return;
      }

      let mnemonicsStr = selected.toString().split(',').join(' ');
      let data = ethers.utils.HDNode.fromMnemonic(mnemonicsStr);

      if (data.address) {
        NotificationManager.success(
          t('notify.create_wallet_success'),
          t('notification'),
        );
        history.push('/access-wallet');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item xs={12} style={{textAlign: 'center', marginBottom: 30}}>
        {/* <img src={createWallet} /> */}
        <Text variant="titlePass">Create your wallet</Text>
        <Typography style={{fontSize: 16, color: '#c4c4c4', marginBottom: 24}}>
          Write down your mnemonic phrase or copy them to your clipboard.
        </Typography>

        <Text variant="titlePhrase">Verify recovery phrase</Text>
        <Typography style={{fontSize: 14, color: '#c4c4c4'}}>
          Arrange your phrase in the correct order.
        </Typography>
      </Grid>
      <Grid container item xs={12} style={{marginTop: 16}}>
        <Grid container item xs={12} className={classes.mnemonicsSelected}>
          <div className={classes.sequenceSelected}>
            {selected.map((mnemonic, index) => (
              <div
                className={classes.mnemonic}
                key={index}
                onClick={() => onPressMnemonic(mnemonic, false)}>
                <span style={{color: '#848484'}}>{index + 1}</span> {mnemonic}
              </div>
            ))}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}>
            {error ? (
              <Typography style={{color: 'red', textAlign: 'center'}}>
                {t('invalid_order')}
              </Typography>
            ) : null}
          </div>
        </Grid>
        <Grid container item xs={12} style={{marginTop: 27}}>
          {mnemonicsShuffle.map((m, k) => (
            <Grid
              item
              md={4}
              xs={4}
              key={k}
              className={classes.wordButton}
              onClick={() => onPressMnemonic(m, true)}>
              <div className={classes.itemPhrases}>
                <span
                  style={{
                    color: !selected.includes(m) ? '#999' : '#fff',
                  }}>
                  {m}
                </span>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item>
        <RoundedButton
          hasIcon
          variant="contained"
          style={{width: 249, marginTop: 24}}
          onClick={handleComplete}>
          Let’s continue
        </RoundedButton>
      </Grid>
      <Grid item xs={12} className={classes.checkBox}>
        <Typography style={{color: '#999', marginRight: 15}}>
          Already have a wallet?
        </Typography>
        <Link
          onClick={() => history.push('/access-wallet')}
          style={{cursor: 'pointer'}}>
          Access wallet
        </Link>
        <CallMadeIcon
          onClick={() => history.push('/access-wallet')}
          sx={{marginLeft: 0.5, color: '#041562', cursor: 'pointer'}}
          fontSize="small"
        />
      </Grid>
    </Grid>
  );
}
