import {Button, styled} from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import {blue} from 'styles/color';
import {Typography} from '@material-ui/core';

const RoundedButton = ({children, hasIcon = false, ...props}) => {
  return (
    <StyledButton {...props}>
      <Typography style={{display: 'flex'}}>
        {children} {hasIcon ? <CallMadeIcon sx={{marginLeft: 0.5}} /> : null}
      </Typography>
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({theme, variant}) => ({
  borderRadius: theme.spacing(5),
  fontSize: theme.spacing(2),
  lineHeight: '20px',
  fontWeight: 400,
  textTransform: 'capitalize',
  padding: theme.spacing(1.5, 2.5),
  ...(variant === 'contained' && {background: blue[800]}),
  ...(variant !== 'contained' && {color: blue[800], borderColor: blue[800]}),
}));

export {RoundedButton};
