import {React, useState} from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import logo from 'assets/images/LOGO_P.png';
import {useFormik} from 'formik';
import {useHistory} from 'react-router-dom';
import {useAPI} from 'api/api';
import * as Yup from 'yup';
import {Text} from 'App';
import {useTranslation} from 'react-i18next';
import {RoundedButton} from 'components';
import shinobiApp from 'assets/images/home/shinobi-app.png';
import createWallet from 'assets/images/create-wallet.png';
import CallMadeIcon from '@mui/icons-material/CallMade';
import StartCreate from './components/StartCreate';
import GeneratePhrase from './components/GeneratePhrase';
import ConfirmPhrase from './components/ConfirmPhrase';

function CreateWallet() {
  const classes = useStyles();
  const theme = useTheme();
  const api = useAPI();
  const {t} = useTranslation();
  const [agree, setAgree] = useState(false);
  const [step, setStep] = useState(1);
  const [mnemonics, setMnemonics] = useState([]);
  const [mnemonicsShuffle, setMnemonicsShuffle] = useState([]);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const handleContinue = () => {
    if (agree) {
      setStep(2);
    } else {
      setStep(1);
    }
  };
  const handleSavedPhrase = (mnemonic, mneShuffle) => {
    setMnemonicsShuffle(mneShuffle);
    setMnemonics(mnemonic);
    setStep(3);
  };

  return (
    <Grid container className={classes.root}>
      {!matchesXs && (
        <Grid item xs={12} md={12} style={{paddingLeft: 20, paddingTop: 20}}>
          <img src={logo} onClick={() => history.push('/')} />
        </Grid>
      )}
      <Grid container justifyContent="center" item xs={12} alignItems="center">
        <Grid
          container
          item
          xs={11}
          sm={10}
          md={5}
          lg={5}
          alignItems="flex-end"
          className={classes.imageBackgroud}>
          <img src={shinobiApp} style={{maxWidth: '100%'}} />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={10}
          md={7}
          lg={7}
          alignItems="flex-start"
          justifyContent={matches ? 'center' : 'flex-start'}
          style={{height: '100%'}}>
          <Grid
            container
            justifyContent="center"
            item
            xs={10}
            md={11}
            lg={10}
            className={classes.paper}>
            {step == 1 && (
              <StartCreate
                handleCheck={() => setAgree((pre) => !pre)}
                handleContinue={handleContinue}
                checked={agree}
              />
            )}
            {step == 2 && (
              <GeneratePhrase handleSavedPhrase={handleSavedPhrase} />
            )}
            {step == 3 && (
              <ConfirmPhrase
                mnemonics={mnemonics}
                mnemonicsShuffle={mnemonicsShuffle}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F2F5FF',
    // padding: '30px 48px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0',

      background: '#fff',
    },
    minHeight: '100vh',
  },
  paper: {
    background: '#fff',
    boxShadow: '25px 25px 50px rgba(0, 0, 0, 0.05)',
    borderRadius: 10,
    padding: theme.spacing(6, 15),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0),
      boxShadow: 'none',
      marginTop: 32,
    },
  },
  imageBackgroud: {
    height: '100%',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
export default CreateWallet;
