import React, {useState, useEffect, useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Modal, Typography, Box, Container} from '@material-ui/core';
import {AuthContext} from 'store/AuthProvider';
import {Button} from 'bootstrap';

import QRCode from 'react-qr-code';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {IS_DEFAULT} from 'common/constants';
import {NotificationManager} from 'react-notifications';
import {useTranslation} from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default function ModalAddress({openModal, handleClose}) {
  const [modalStyle] = React.useState(getModalStyle);
  const {wallet} = useContext(AuthContext);
  const [activeWallet, setActiveWallet] = useState();
  const [btcWallet, setBtcWallet] = useState();
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const {t} = useTranslation();
  useEffect(() => {
    if (wallet.length > 0) {
      const index = wallet.findIndex(
        (val) => val.is_default == IS_DEFAULT['ACTIVE'],
      );
      if (index >= 0) {
        setActiveWallet(wallet[index]?.address);
        setBtcWallet(wallet[index]?.btcAddress);
      }
    }
  }, [wallet]);
  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);
  const onCopied = () => {
    NotificationManager.success('COPIED!', t('notification'));
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Modal open={openModal} onClose={handleClose}>
        <div style={modalStyle} className="contentModal max500">
          <div className="header">
            <div className="modalTitle">Wallet Address</div>
            <div onClick={handleClose}>
              <CloseIcon style={{color: '#fff'}} />
            </div>
          </div>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            // indicatorColor="primary"
            // textColor="primary"
            // className={classes.tabs}
            // classes={{indicator: classes.indicator}}
            aria-label="icon tabs example">
            <Tab
              label="ETH Wallet"
              aria-label="phone"
              // style={{background: '#bbeaed', padding: 10}}
              // classes={{selected: classes.selectedTab}}
              // className={classes.tab}
            />
            {btcWallet && (
              <Tab
                label="BTC Wallet"
                aria-label="favorite"
                // classes={{selected: classes.selectedTab}}
                // className={classes.tab}
              />
            )}
          </Tabs>
          <div
            style={{
              textAlign: 'center',
              margin: '40px 0',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}>
            <TabPanel value={value} index={0}>
              <QRCode value={activeWallet} />
              <Typography
                style={{marginTop: 20, wordBreak: 'break-all', padding: 20}}>
                {activeWallet}
              </Typography>
              <CopyToClipboard text={activeWallet} onCopy={onCopied}>
                <button
                  title="copy"
                  style={{
                    border: 'none',
                    backgroundColor: 'write',
                    marginTop: 20,
                  }}>
                  <FileCopyIcon style={{cursor: 'pointer'}} />
                </button>
              </CopyToClipboard>
            </TabPanel>
            {btcWallet && (
              <TabPanel value={value} index={1}>
                <QRCode value={btcWallet} />
                <Typography
                  style={{marginTop: 20, wordBreak: 'break-all', padding: 20}}>
                  {btcWallet}
                </Typography>
                <CopyToClipboard text={btcWallet} onCopy={onCopied}>
                  <button
                    title="copy"
                    style={{
                      border: 'none',
                      backgroundColor: 'write',
                      marginTop: 20,
                    }}>
                    <FileCopyIcon style={{cursor: 'pointer'}} />
                  </button>
                </CopyToClipboard>
              </TabPanel>
            )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}>
      {value === index && (
        <Container>
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 100,
  },
  colorChangeDown: {
    color: 'red',
  },
  colorChangeUp: {
    color: 'green',
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '95%',
  };
}
