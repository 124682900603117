import bitcore from 'bitcore-lib';
import {getNetworkBtc} from './utils';
import BigNumber from 'bignumber.js';
import {Buffer} from 'buffer';

export const UNITS = {
  btc: {
    toSatoshis: 1e8,
    toBitcoin: 1,
  },
  sat: {
    toSatoshis: 1,
    toBitcoin: 1e8,
  },
};

const NETWORK_BTC = getNetworkBtc();
export function loadBtcWalletFromMnemonics(mnemonics) {
  if (!(mnemonics instanceof Array) && typeof mnemonics !== 'string') {
    throw new Error('invalid mnemonic');
  } else if (mnemonics instanceof Array) {
    mnemonics = mnemonics.join(' ');
  }
  const value = new Buffer(mnemonics);
  const hash = bitcore.crypto.Hash.sha256(value);
  const bn = bitcore.crypto.BN.fromBuffer(hash);
  const privateKey = bitcore.PrivateKey(bn, NETWORK_BTC);
  const address = privateKey.toAddress();
  return {address: address.toString(), privateKey: privateKey.toString()};
}

export function bitcoinToSatoshi(bitcoin) {
  const bitcoinBig = BigNumber(bitcoin);
  return bitcoinBig.isNaN()
    ? BigNumber(0)
    : bitcoinBig.times(UNITS.btc.toSatoshis);
}

export function satoshiToBitcoin(satoshi) {
  const satoshiBig = BigNumber(satoshi).decimalPlaces(0, 1);
  return satoshiBig.isNaN()
    ? BigNumber(0)
    : satoshiBig.div(UNITS.sat.toBitcoin);
}
