import PrivacyPolicy from 'containers/PrivacyPolicy/PrivacyPolicy';
import {lazy} from 'react';
//-------------------------------------
const SendTokens = lazy(() => import('containers/Wallet/Send/Tokens'));
const Exchange = lazy(() => import('containers/Swap'));
const Stake = lazy(() => import('containers/Stake/containers/Token'));
const StakePlan = lazy(() => import('containers/Stake/containers/Plan'));
const StakeDetail = lazy(() => import('containers/Stake/containers/Detail'));
const TokenList = lazy(() =>
  import('containers/Home/containers/Interface/Token/TokenList'),
);
const ListAllToken = lazy(() =>
  import('containers/Home/containers/Interface/Token/ListAllToken'),
);

const Swap = lazy(() => import('containers/SwapUni'));
const sUKHvsbUKH = lazy(() => import('containers/sUKHvsbUKH'));
const Wrap = lazy(() => import('containers/Wrap'));
const WhiteList = lazy(() => import('containers/WhiteList'));
const InquiryForm = lazy(() => import('containers/WhiteList/inquiryForm'));
const ThankYou = lazy(() => import('containers/WhiteList/thankyou'));
const CreditCard = lazy(() => import('containers/CreditCard'));
const RateList = lazy(() => import('containers/CreditCard/RateList'));
// const Contact = lazy(() =>
//   import('containers/Home/containers/FooterSection/contact'),
// );
const Blogs = lazy(() => import('containers/Blogs/Blogs'));
const TermsOfUse = lazy(() => import('containers/TermsOfUse/TermsOfUse'));
const AboutUs = lazy(() => import('containers/Footer/AboutUs/AboutUs'));
const Cookie = lazy(() => import('containers/Footer/Cookie/Cookie'));
const HelpCenter = lazy(() =>
  import('containers/Footer/HelpCenter/HelpCenter'),
);
const Partner = lazy(() => import('containers/Footer/FormPartner/Partner'));
const Career = lazy(() => import('containers/Footer/Careers/Career'));
const Contact = lazy(() => import('containers/Footer/ContactUs/ContactUs'));

const routes = [
  // {
  //   path: '/create-wallet',
  //   component: WalletCreate,
  // },
  // {
  //   path: '/access-wallet',
  //   component: AccessWallet,
  // },
  {
    path: '/exchange',
    component: Exchange,
    protected: true,
  },
  {
    path: '/swap',
    component: Swap,
    protected: true,
  },
  {
    path: '/wrap',
    component: Wrap,
    protected: true,
  },
  {
    path: '/stake',
    component: Stake,
    protected: true,
  },
  {
    path: '/stake/:id',
    component: StakePlan,
    protected: true,
  },
  {
    path: '/stake-detail/:id',
    component: StakeDetail,
    protected: true,
  },
  {
    path: '/token-default',
    component: TokenList,
    protected: true,
  },

  {
    path: '/all-tokens',
    component: ListAllToken,
    protected: true,
  },
  {
    path: '/send',
    component: SendTokens,
    protected: true,
  },
  {
    path: '/sukh-bukh',
    component: sUKHvsbUKH,
    protected: true,
  },
  {
    path: '/white-list',
    component: WhiteList,
  },
  {
    path: '/inquiry',
    component: InquiryForm,
  },
  // {
  //   path: '/thank-you',
  //   component: ThankYou,
  // },

  {
    path: '/contact',
    component: Contact,
  },
  {
    path: '/buy-credit-card',
    component: CreditCard,
  },
  {
    path: '/buy-credit-card/rate/:symbol',
    component: RateList,
  },
  {
    path: '/blogs',
    component: Blogs,
  },
  {
    path: '/terms-of-use',
    component: TermsOfUse,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/about-us',
    component: AboutUs,
  },
  {
    path: '/cookie',
    component: Cookie,
  },
  {
    path: '/help-center',
    component: HelpCenter,
  },
  {
    path: '/partners',
    component: Partner,
  },
  {
    path: '/careers',
    component: Career,
  },
];

export default routes;
