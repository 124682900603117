import {React, useEffect, useState} from 'react';
import {
  Button,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import logo from 'assets/images/LOGO_P.png';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
function ThankYou() {
  const classes = useStyles();
  const theme = useTheme();
  const {t} = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();

  return (
    <Grid container className={classes.root}>
      {/* {!matches && ( */}
      <Grid item xs={12} md={12}>
        <img src={logo} onClick={() => history.push('/')} />
      </Grid>
      {/* )} */}
      <Grid container justifyContent="center" item xs={12}>
        <Grid item xs={10} md={12}>
          <Typography
            variant="h3"
            style={{textAlign: 'center', marginBottom: 50}}>
            {t('thank_you.title')}
          </Typography>
          <Typography
            variant="h5"
            style={{textAlign: 'center', marginBottom: 50, marginTop: 20}}>
            {t('thank_you.description')}
            <br />
            <br />
            <br />
            <Link onClick={() => history.push('/')} style={{cursor: 'pointer'}}>
              {t('thank_you.return_home')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    background: '#F2F5FF',
    padding: '30px 48px',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0',

      background: '#fff',
    },
    minHeight: '100vh',
  },
}));
export default ThankYou;
