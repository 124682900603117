import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {blue, lime, teal, grey, red, yellow} from '@material-ui/core/colors';

export const ButtonBlue = withStyles((theme) => ({
  root: {
    marginBottom: 25,
    textTransform: 'initial',
    marginTop: 25,
    width: '100%',
    color: 'white',
    borderRadius: 6,
    padding: '21px 55px',
    backgroundColor: 'rgb(79, 68, 108)',
    '&:hover': {
      backgroundColor: 'rgb(79, 68, 108)',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 15px',
    },
  },
}))(Button);

export const ButtonSubmitSend = withStyles((theme) => ({
  root: {
    marginBottom: 10,
    textTransform: 'initial',
    marginTop: 20,
    width: '100%',
    color: 'white',
    borderRadius: 6,
    padding: 15,
    backgroundColor: 'rgb(79, 68, 108)',
    '&:hover': {
      backgroundColor: 'rgb(79, 68, 108)',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 15px',
    },
  },
}))(Button);

export const ButtonClose = withStyles((theme) => ({
  root: {
    marginBottom: 0,
    textTransform: 'initial',
    marginTop: 30,
    width: '60%',
    color: 'white',
    borderRadius: 6,
    padding: 15,
    backgroundColor: 'rgb(220, 0, 78)',
    '&:hover': {
      backgroundColor: 'rgb(220, 0, 78)',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 15px',
    },
  },
}))(Button);

export const ButtonBlueSend = withStyles((theme) => ({
  root: {
    marginBottom: 10,
    textTransform: 'initial',
    marginTop: 20,
    width: '45%',
    color: 'white',
    borderRadius: 6,
    marginRight: 10,
    padding: 15,
    backgroundColor: 'rgb(79, 68, 108)',
    '&:hover': {
      backgroundColor: 'rgb(79, 68, 108)',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 15px',
    },
  },
}))(Button);

export const ButtonMenuCustom = withStyles((theme) => ({
  root: {
    textTransform: 'initial',
    color: '#506175',
    marginLeft: 20,
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      fontSize: '1rem',
    },
  },
}))(Button);

export const ButtonAction = withStyles((theme) => ({
  root: {
    textTransform: 'initial',
    marginRight: 5,
    backgroundColor: '#2c2b3b',
    color: '#fff',
    borderRadius: 15,
    '&:hover': {
      backgroundColor: '#d0d0d0',
      color: '#fff',
      // fontWeight: 'bold'
    },
    '&:disabled': {
      color: '#fff',
    },
  },
}))(Button);

export const ButtonActionSend = withStyles((theme) => ({
  root: {
    textTransform: 'initial',
    marginRight: 5,
    padding: 15,
    color: '#506175',
  },
}))(Button);

// export const IconButtonStyle = withStyles((theme) => ({
//   root: {
//     border: '1.5px solid #000',
//     borderRadius: '50%',
//     width: 50,
//     margin: '5px 0',
//     height: 50,
//     [theme.breakpoints.down('xs')]: {
//       width: 40,
//       height: 40,
//       margin: '0 5px',
//       border: '1px solid #000',
//     },
//   },
// }))(IconButton);
