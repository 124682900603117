import {Chip, styled} from '@mui/material';

const HeaderTag = ({variant = 'outlined', color = 'error', ...props}) => {
  return <StyledChip variant={variant} color={color} {...props} />;
};

const StyledChip = styled(Chip)(({theme, variant, color = 'error'}) => ({
  ...(variant === 'outlined' && {
    ...(color === 'error' && {
      borderColor: 'rgba(218, 18, 18, 0.07)',
      background: 'rgba(218, 18, 18, 0.07)',
    }),

    ...(color === 'success' && {
      borderColor: 'rgba(0, 190, 42, 0.1)',
      background: 'rgba(0, 190, 42, 0.1)',
    }),
  }),
  padding: theme.spacing(1, 5),
}));

export {HeaderTag};
