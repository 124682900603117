import FooterSection from 'containers/Home/containers/FooterSection';
import React from 'react';
import MainNavbar from './Navbar';

function MainLayout(props) {
  return (
    <>
      <MainNavbar />
      <div className="content">{props.children}</div>
      <FooterSection />
    </>
  );
}
export default MainLayout;
