import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
  Grid,
  makeStyles,
  Button,
  Typography,
  withStyles,
  TextField,
  Link,
} from '@material-ui/core';
import {ButtonBlue} from '../../../../components/StyledButtons';
import {loadWalletFromPrivateKey} from '../../../../common/wallet';
import {NotificationManager} from 'react-notifications';
import {AuthContext} from 'store/AuthProvider';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Text} from 'App';
import {RoundedButton} from 'components';
import CallMadeIcon from '@mui/icons-material/CallMade';

const useStyles = makeStyles((theme) => ({
  checkBox: {
    display: 'flex',
    marginTop: 29,
    marginBottom: 37,
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: 0,
    },
  },
}));

export default function ImportByPrivateKey() {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [privateKey, setPrivateKey] = useState('');
  const {updateWallet} = useContext(AuthContext);
  const history = useHistory();
  const {t} = useTranslation();

  const handleChange = (value) => {
    setPrivateKey(value);
  };
  const handleOpenWallet = async () => {
    try {
      let walletGenerate = {};

      if (privateKey && privateKey.trim().length < 64) {
        setError(true);
        return;
      }

      walletGenerate = loadWalletFromPrivateKey(privateKey);
      if (walletGenerate.address) {
        await updateWallet(
          walletGenerate.address,
          walletGenerate.privateKey,
          walletGenerate.mnemonic ? walletGenerate.mnemonic.phrase : null,
        );
        NotificationManager.success(
          t('notify.access_wallet_success'),
          t('notification'),
        );
        history.push('/token-default');
      }
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item xs={12} style={{textAlign: 'center', marginBottom: 48}}>
        {/* <img src={createWallet} /> */}
        <Text variant="titlePass">Access your wallet</Text>
        <Typography style={{fontSize: 16, color: '#c4c4c4', marginBottom: 30}}>
          You can access your wallet using any of the following ways.
        </Typography>
        <Text variant="titlePhrase">Enter your private key</Text>
      </Grid>
      <Grid container spacing={3}>
        <TextField
          value={privateKey}
          onChange={(e) => handleChange(e.target.value)}
          // jnnni
          fullWidth
          placeholder={t('access_wallet.modal.enter_private_key')}
          variant="outlined"
          // place

          InputProps={{
            fullWidth: true,
            // classes: {
            //   input: {backgroundColor: '#f9f9f9'},
            // },
          }}
          style={{marginTop: 40, marginBottom: 20}}
        />

        {error ? (
          <Typography
            style={{color: 'red', marginTop: 20, textAlign: 'center'}}>
            {t('invalid_privateKey')}
          </Typography>
        ) : null}
      </Grid>
      {error == 'invalid checksum' ? (
        <Typography style={{color: 'red', marginTop: 12, textAlign: 'center'}}>
          {t('invalid_order')}
        </Typography>
      ) : error == 'invalid mnemonic' ? (
        <Typography style={{color: 'red', marginTop: 12, textAlign: 'center'}}>
          {t('invalid_mnemonic')}
        </Typography>
      ) : error == 'invalid_paste' ? (
        <Typography style={{color: 'red', marginTop: 12, textAlign: 'center'}}>
          You only can paste the phrase with exact 12 words!
        </Typography>
      ) : null}
      <Grid container item xs={12} className={classes.footer}>
        <Grid item xs={12} container justifyContent="center">
          <RoundedButton
            hasIcon
            variant="contained"
            style={{width: 249, marginTop: 42}}
            onClick={handleOpenWallet}>
            Verify
          </RoundedButton>
        </Grid>
        <Grid item xs={12} className={classes.checkBox}>
          <Typography style={{color: '#999', marginRight: 15}}>
            Don’t have a wallet?
          </Typography>
          <Link
            onClick={() => history.push('/create-wallet')}
            style={{cursor: 'pointer'}}>
            Create wallet
          </Link>
          <CallMadeIcon
            onClick={() => history.push('/create-wallet')}
            sx={{marginLeft: 0.5, color: '#041562', cursor: 'pointer'}}
            fontSize="small"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
