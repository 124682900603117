import React, {useState, useEffect, useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Modal, Typography} from '@material-ui/core';
import {AuthContext} from 'store/AuthProvider';
import CloseIcon from '@material-ui/icons/Close';
import {IS_DEFAULT} from 'common/constants';
import {ButtonClose} from 'components/StyledButtons';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {NotificationManager} from 'react-notifications';
import {useTranslation} from 'react-i18next';

export default function ModalPrivateKey({openModal, handleClose}) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const {wallet} = useContext(AuthContext);
  const [privateKey, setPrivateKey] = useState();
  const [btcPrivateKey, setBtcPrivateKey] = useState();
  const {t} = useTranslation();
  useEffect(() => {
    if (wallet.length > 0) {
      const index = wallet.findIndex(
        (val) => val.is_default == IS_DEFAULT['ACTIVE'],
      );
      if (index >= 0) {
        if (wallet[index].privateKey) {
          setPrivateKey(wallet[index].privateKey);
        }
        if (wallet[index].btcPrivateKey) {
          setBtcPrivateKey(wallet[index].btcPrivateKey);
        }
      }
    }
  }, [wallet]);
  const onCopied = () => {
    NotificationManager.success('COPIED!', t('notification'));
  };
  return (
    <React.Fragment>
      <Modal open={openModal} onClose={handleClose}>
        <div style={modalStyle} className="contentModal max500">
          <div className="header">
            <div className="modalTitle">{t('private key')}</div>
            <div onClick={handleClose} style={{cursor: 'pointer'}}>
              <CloseIcon style={{color: '#fff'}} />
            </div>
          </div>
          <div className={classes.content}>
            <div>
              <div style={{textAlign: 'left'}}>
                <Typography style={{fontWeight: 600}}>{t('ethereum_wallet')} :</Typography>
              </div>
              <Typography>{privateKey}</Typography>
              <CopyToClipboard text={privateKey} onCopy={onCopied}>
                <button
                    title="copy"
                    style={{
                      border: 'none',
                      backgroundColor: 'write',
                      marginTop: 20,
                    }}>
                  <FileCopyIcon style={{cursor: 'pointer'}} />
                </button>
              </CopyToClipboard>
            </div>
            {
              btcPrivateKey ?
                  <div style={{marginTop: 40}}>
                    <div style={{textAlign: 'left'}}>
                      <Typography  style={{fontWeight: 600}}>{t('btc_wallet')} :</Typography>
                    </div>
                    <Typography>{btcPrivateKey}</Typography>
                    <CopyToClipboard text={btcPrivateKey} onCopy={onCopied}>
                      <button
                          title="copy"
                          style={{
                            border: 'none',
                            backgroundColor: 'write',
                            marginTop: 20,
                          }}>
                        <FileCopyIcon style={{cursor: 'pointer'}} />
                      </button>
                    </CopyToClipboard>
                  </div>
                  : null
            }
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 100,
  },

  contentMnemonic: {
    borderBottom: '1px solid #e0e0e0',
    color: '#999',
    display: 'flex',
    padding: '10px 10px',
  },
  content: {
    textAlign: 'center',
    margin: '40px 0',
    padding: '0 30px',
    wordWrap: 'break-word',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  colorTextField: {
    '&$disabled': {
      color: '#000',
    },
  },
  disabled: {},
}));

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: '95%',
  };
}
