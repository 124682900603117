import React, {useEffect, useMemo, useState} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Button,
  Typography,
  Checkbox,
  Link,
  SvgIcon,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Text} from 'App';
import {RoundedButton} from 'components';
import CallMadeIcon from '@mui/icons-material/CallMade';
import {ethers} from 'ethers';
import {checkForDuplicates, shuffle} from 'common/utils';
import {ReactComponent as copyIcon} from 'assets/images/svg/copy.svg';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {NotificationManager} from 'react-notifications';
import {useHistory} from 'react-router-dom';

export default function GeneratePhrase({handleSavedPhrase}) {
  const useStyles = makeStyles((theme) => ({
    warning: {
      backgroundColor: '#fbe7e7',
      padding: '24px 40px',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: '14px 8px 21px 12px',
      },
    },
    checkBox: {
      display: 'flex',
      marginTop: 29,
      marginBottom: 37,
      alignItems: 'center',
      justifyContent: 'center',
    },
    itemPhrases: {
      border: '1px solid #d2d2d2',
      padding: 8,
      borderRadius: 6,
      textAlign: 'center',
    },
    wordButton: {
      padding: '8px 16px',
      [theme.breakpoints.down('xs')]: {
        padding: '8px 4px',
      },
    },
  }));

  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();
  const [mnemonics, setMnemonics] = useState([]);
  const [refresh, setRefresh] = useState(Math.random);
  const [mnemonicsShuffle, setMnemonicsShuffle] = useState([]);

  useEffect(() => {
    generatePhrase();
  }, []);
  const generatePhrase = () => {
    let walletMnemonics = ethers.utils
      .entropyToMnemonic(ethers.utils.randomBytes(16))
      .split(' ');
    setMnemonics(walletMnemonics);
    setMnemonicsShuffle(shuffle([...walletMnemonics]));
  };

  useEffect(() => {
    if (checkForDuplicates(mnemonics)) {
      generatePhrase();
    }
  }, [mnemonics]);
  const onCopied = () => {
    NotificationManager.success('COPIED!', t('notification'));
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item xs={12} style={{textAlign: 'center'}}>
        {/* <img src={createWallet} /> */}
        <Text variant="titlePass">Create your wallet</Text>
        <Typography style={{fontSize: 16, color: '#c4c4c4', marginBottom: 30}}>
          Write down your mnemonic phrase or copy them to your clipboard.
        </Typography>
        <Text variant="titlePhrase">Your mnemonic phrase</Text>
      </Grid>
      <Grid item xs={12} className={classes.warning}>
        <Text variant="description1" style={{color: 'red'}}>
          Ensure you keep your recovery phrase safe and do not share with any
          other person.
        </Text>
      </Grid>
      <Grid container item xs={12} style={{marginTop: 27}}>
        {mnemonics.map((m, k) => (
          <Grid item md={4} xs={4} key={k} className={classes.wordButton}>
            <div className={classes.itemPhrases}>
              <span style={{color: '#999'}}>
                {k + 1}. {m}
              </span>
            </div>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} justifyContent="center" className={classes.checkBox}>
        <CopyToClipboard text={mnemonics.join(' ')} onCopy={onCopied}>
          <Typography
            style={{color: '#041562', display: 'flex', cursor: 'pointer'}}>
            <SvgIcon component={copyIcon} viewBox="0 0 18 18" />
            Copy to clipboard
          </Typography>
        </CopyToClipboard>
      </Grid>

      <RoundedButton
        hasIcon
        variant="contained"
        style={{width: 249}}
        onClick={() => handleSavedPhrase(mnemonics, mnemonicsShuffle)}>
        I’ve saved phrase
      </RoundedButton>
      <Grid item xs={12} className={classes.checkBox}>
        <Typography style={{color: '#999', marginRight: 15}}>
          Already have a wallet?
        </Typography>
        <Link
          onClick={() => history.push('/access-wallet')}
          style={{cursor: 'pointer'}}>
          Access wallet
        </Link>
        <CallMadeIcon
          onClick={() => history.push('/access-wallet')}
          sx={{marginLeft: 0.5, color: '#041562', cursor: 'pointer'}}
          fontSize="small"
        />
      </Grid>
    </Grid>
  );
}
