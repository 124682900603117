import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Button,
  Typography,
  InputAdornment,
  withStyles,
  TextField,
  Link,
} from '@material-ui/core';
import {ButtonBlue} from '../../../../components/StyledButtons';
import {ethers} from 'ethers';
import {loadWalletFromMnemonics} from '../../../../common/wallet';
import {NotificationManager} from 'react-notifications';
import {AuthContext} from 'store/AuthProvider';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {loadBtcWalletFromMnemonics} from '../../../../common/btcService';
import {Text} from 'App';
import {RoundedButton} from 'components';
import CallMadeIcon from '@mui/icons-material/CallMade';

const useStyles = makeStyles((theme) => ({
  mnemonics: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    // wordBreak: 'break-all',
    padding: '20px 10px',
    // justifyContent: 'center',
    minHeight: 150,
    // padding: 10,
  },
  contentTop: {
    color: '#506175',
    textAlign: 'center',
    marginBottom: 20,
  },
  contentMnemonic: {
    border: '1px solid #d2d2d2',
    padding: 8,
    borderRadius: 6,
  },
  checkBox: {
    display: 'flex',
    marginTop: 29,
    marginBottom: 37,
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: 0,
    },
  },
}));

export default function ImportByMnemonic() {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [mnemonics, setMnemonics] = useState([]);
  const {updateWallet} = useContext(AuthContext);
  const history = useHistory();
  const {t} = useTranslation();

  useEffect(() => {
    let intArr = [];
    for (let i = 0; i < 12; i++) {
      intArr.push('');
    }
    setMnemonics(intArr);
  }, []);

  const handleChangeMnemonic = (newValue, index) => {
    setError(false);
    const trimValue = newValue.trim();
    const arrayValue = trimValue.split(' ');
    if (arrayValue.length === 12) {
      setMnemonics(arrayValue);
    } else if (arrayValue.length === 1) {
      let mnemonicValue = [...mnemonics];
      mnemonicValue[index] = newValue.trim();
      setMnemonics(mnemonicValue);
    } else {
      setError('invalid_paste');
    }
  };
  const handleOpenWallet = async () => {
    try {
      let walletGenerate = {};
      // let mnemonic1 = "announce room limb pattern dry unit scale effort smooth jazz weasel alcohol"
      walletGenerate = loadWalletFromMnemonics(mnemonics);

      const btcWallet = loadBtcWalletFromMnemonics(mnemonics);
      if (walletGenerate.address) {
        await updateWallet(
          walletGenerate.address,
          walletGenerate.privateKey,
          walletGenerate.mnemonic ? walletGenerate.mnemonic.phrase : null,
          btcWallet ? btcWallet.address : null,
          btcWallet ? btcWallet.privateKey : null,
        );

        NotificationManager.success(
          t('notify.access_wallet_success'),
          t('notification'),
        );
        history.push('/token-default');
      }
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item xs={12} style={{textAlign: 'center', marginBottom: 48}}>
        {/* <img src={createWallet} /> */}
        <Text variant="titlePass">Access your wallet</Text>
        <Typography style={{fontSize: 16, color: '#c4c4c4', marginBottom: 30}}>
          You can access your wallet using any of the following ways.
        </Typography>
        <Text variant="titlePhrase">Enter your mnemonic phrase</Text>
      </Grid>
      <Grid container spacing={3}>
        {mnemonics.map((m, i) => (
          <Grid item md={4} xs={4} key={i}>
            <TextField
              className={classes.contentMnemonic}
              label=""
              // color={'blue'}
              value={m}
              onChange={(e) => handleChangeMnemonic(e.target.value, i)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {i + 1}. {'  '}
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
            />
          </Grid>
        ))}
      </Grid>
      {error == 'invalid checksum' ? (
        <Typography style={{color: 'red', marginTop: 12, textAlign: 'center'}}>
          {t('invalid_order')}
        </Typography>
      ) : error == 'invalid mnemonic' ? (
        <Typography style={{color: 'red', marginTop: 12, textAlign: 'center'}}>
          {t('invalid_mnemonic')}
        </Typography>
      ) : error == 'invalid_paste' ? (
        <Typography style={{color: 'red', marginTop: 12, textAlign: 'center'}}>
          You only can paste the phrase with exact 12 words!
        </Typography>
      ) : null}

      <Grid container item xs={12} className={classes.footer}>
        <Grid item xs={12} container justifyContent="center">
          <RoundedButton
            hasIcon
            variant="contained"
            style={{width: 249, marginTop: 42}}
            onClick={handleOpenWallet}>
            Verify
          </RoundedButton>
        </Grid>
        <Grid item xs={12} className={classes.checkBox}>
          <Typography style={{color: '#999', marginRight: 15}}>
            Don’t have a wallet?
          </Typography>
          <Link
            onClick={() => history.push('/create-wallet')}
            style={{cursor: 'pointer'}}>
            Create wallet
          </Link>
          <CallMadeIcon
            onClick={() => history.push('/create-wallet')}
            sx={{marginLeft: 0.5, color: '#041562', cursor: 'pointer'}}
            fontSize="small"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
