import {useEffect} from 'react';

const useIntersectionObserver = (setVisibleId) => {
  useEffect(() => {
    const callback = (visibleElements) => {
      visibleElements = visibleElements.filter((e) => e?.isIntersecting);
      if (visibleElements.length > 0) {
        const mostVisibleElement = visibleElements?.reduce(function (
          prev,
          current,
        ) {
          return prev.y > current.y ? prev : current;
        });
        setVisibleId(mostVisibleElement.target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: '-100px 0px -70% 0px',
    });

    const headingElements = Array.from(
      document.querySelectorAll('.title-nav-item'),
    );
    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, []);
};

export {useIntersectionObserver};
