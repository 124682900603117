import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react';
// eslint-disable-next-line no-unused-vars
// import Cookies from 'js-cookie';
import {useAPI, useFetch} from '../api/api';
import {useLocation} from 'react-router-dom';
import {IS_DEFAULT, TYPE_TOKEN} from 'common/constants';
import {storeData} from './StoreProvider';
import {loadPrivateKey} from '../common/wallet';
import {
  getBscScanAllTransitionUrl,
  getBscScanInternalTransitionUrl,
  getBscScanTransactionUrl,
  getEtherscanAllTransitionUrl,
  getEtherscanCheckTransitionUrl,
  getEtherscanInternalTransitionUrl,
  getPolygonScanAllTransitionUrl,
  getPolygonScanInternalTransitionUrl,
  getPolygonScanTransactionUrl,
} from 'common/utils';
import axios from 'axios';
import {ethers} from 'ethers';
import {act} from 'react-dom/cjs/react-dom-test-utils.production.min';
import {Transaction} from 'bitcore-lib';
//-------------------------------------
export const AuthContext = createContext(null);

/**
 * Restore auth user from access_token is persisted in localStorage.
 *
 * TODO: handle refresh token in here.
 */
const AuthProvider = React.memo((props) => {
  const [wallet, setWallet] = useState([]);
  // const [currentWallet, setCurrentWallet] = useState();
  const api = useAPI();
  const {state, dispatch} = useContext(storeData);
  const {data: dataTokens, loading: Loading} = useFetch([
    'get',
    'tokens/default',
  ]);

  const [tokenDefault, setTokenDefault] = useState([]);
  const [tokenAdd, setTokenAdd] = useState([]);
  const [step, setStep] = useState(0);
  const [totalMoney, setTotalMoney] = useState(0.0);
  const [activeWallet, setActiveWallet] = useState();
  const [loadingToken, setLoadingToken] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const METHODETH = {
    SWAP_FROM: '0x344933be',
    SEND_RECEIVE: '0x',
    CLAIM: '0xaad3ec96',
    STAKE: '0x22d4a175',
    WRAP_KGR: '0x42966c68',
    WRAP_WKGR: '0xa0712d68',
  };
  const METHODBSC = {
    SWAP_FROM: '0x344933be',
    SEND_RECEIVE: '0x',
    CLAIM: '0xaad3ec96',
    STAKE: '0x22d4a175',
  };
  const METHODPOLYGON = {
    SWAP_FROM: '0x344933be',
    SEND_RECEIVE: '0x',
    CLAIM: '0xaad3ec96',
    STAKE: '0x22d4a175',
    WITHDRAW: '0x2e1a7d4d',
  };
  useEffect(() => {
    if (wallet.length > 0) {
      const index = wallet.findIndex(
        (val) => val.is_default == IS_DEFAULT['ACTIVE'],
      );
      if (index == -1) {
        setTokenDefault([]);
      } else {
        setActiveWallet(wallet[index].address);
      }
    }
  }, [wallet]);

  useEffect(() => {
    if (dataTokens && step === 0) {
      const defaultTokens = dataTokens?.data;
      setTokenDefault(defaultTokens);
      setStep(1);
    }
  }, [dataTokens, wallet, step]);

  useEffect(() => {
    if (Loading) {
      setLoadingToken(true);
    }
  }, [Loading]);

  async function addHistoryToken(
    item,
    tokenDefault,
    tokenTx,
    tokenInternal,
    listtx,
    hashSwap,
    hashInternals,
    hashBaseToken,
    currentAddress,
    baseToken,
    METHOD,
  ) {
    if (tokenTx?.data.result.length > 0) {
      let transaction;

      tokenTx?.data?.result.forEach((element) => {
        if (
          tokenTx?.data?.result.filter((e) => e?.hash === element?.hash)
            ?.length > 1 &&
          hashSwap.filter((e) => e.hash == element.hash)?.length < 2
        ) {
          const transactionswap = tokenTx.data.result.filter(
            (e) => e.hash === element.hash,
          );
          let swapfrom = transactionswap.find(
            (tf) => tf.from.toUpperCase() === currentAddress.toUpperCase(),
          );
          let swapto = transactionswap.find(
            (tt) => tt.to.toUpperCase() === currentAddress.toUpperCase(),
          );
          if (swapfrom && swapto) {
            const input_tx = listtx?.data?.result
              .find((e) => e.hash === element?.hash)
              ?.input.slice(0, 10);
            transaction = {
              walletAddress: currentAddress,
              fromToken: swapfrom?.tokenSymbol,
              fromValue: (
                Number(swapfrom?.value) /
                Math.pow(10, Number(swapfrom?.tokenDecimal))
              ).toFixed(4),
              toValue: (
                Number(swapto?.value) /
                Math.pow(10, Number(swapto?.tokenDecimal))
              ).toFixed(4),
              toToken: swapto?.tokenSymbol,
              hash: element?.hash,
              title:
                input_tx == METHOD['WRAP_KGR'] ||
                input_tx == METHOD['WRAP_WKGR']
                  ? 'wrap'
                  : 'swap',
            };
            if (
              element.contractAddress &&
              item.address &&
              element?.contractAddress.toUpperCase() ===
                item.address.toUpperCase()
            ) {
              if (item.history) {
                item.history.push(transaction);
              } else {
                item.history = [transaction];
              }
              hashSwap.push(element.hash);
            }
          }
        } else if (hashInternals.includes(element.hash)) {
          const internal = tokenInternal?.data?.result.find(
            (el) => el.hash === element?.hash,
          );
          transaction = {
            walletAddress: currentAddress,
            fromToken: element?.tokenSymbol,
            fromValue: (
              Number(element?.value) /
              Math.pow(10, Number(element?.tokenDecimal))
            ).toFixed(4),
            toValue: (Number(internal?.value) / 1e18).toFixed(4),
            toToken: baseToken,
            hash: element?.hash,
            title: 'swap',
          };
          if (
            (element.contractAddress &&
              item.address &&
              element?.contractAddress.toUpperCase() ===
                item.address.toUpperCase()) ||
            item.symbol == baseToken
          ) {
            if (item.history) {
              item.history.push(transaction);
            } else {
              item.history = [transaction];
            }
          }
        } else if (
          listtx?.data?.result.length > 0 &&
          listtx?.data?.result
            .find((e) => e.hash === element?.hash)
            ?.input.slice(0, 10) === METHOD['SWAP_FROM']
        ) {
          const tx = listtx?.data?.result.find(
            (tx) =>
              tx.input.slice(0, 10) === METHOD['SWAP_FROM'] &&
              tx?.hash === element?.hash &&
              tx?.value > 0,
          );
          transaction = {
            walletAddress: currentAddress,
            fromToken: baseToken,
            fromValue: (Number(tx?.value) / 1e18).toFixed(4),
            toValue: (
              Number(element?.value) /
              Math.pow(10, Number(element?.tokenDecimal))
            ).toFixed(4),
            toToken: element?.tokenSymbol,
            hash: element?.hash,
            title: 'swap',
            timeStamp: tx.timeStamp,
          };
          if (
            item.symbol === baseToken ||
            (element.contractAddress &&
              item.address &&
              element?.contractAddress.toUpperCase() ===
                item.address.toUpperCase())
          ) {
            if (item.history) {
              item.history.push(transaction);
            } else {
              item.history = [transaction];
            }
            hashSwap.push(element.hash);
          }
        } else if (
          listtx?.data?.result.length > 0 &&
          listtx?.data?.result
            .find((e) => e.hash === element?.hash)
            ?.input.slice(0, 10) === METHOD['STAKE']
        ) {
          transaction = {
            from: element?.from,
            to: element?.to,
            value: Number(
              element?.value / Math.pow(10, Number(element?.tokenDecimal)),
            ).toFixed(4),
            title: 'stake',
            hash: element?.hash,
          };
          if (
            element.contractAddress &&
            item.address &&
            element?.contractAddress.toUpperCase() ===
              item.address.toUpperCase()
          ) {
            if (item.history) {
              item.history.push(transaction);
            } else {
              item.history = [transaction];
            }
          }
        } else if (
          listtx?.data.result.length > 0 &&
          listtx?.data.result
            .find((e) => e.hash === element.hash)
            ?.input.slice(0, 10) === METHOD['CLAIM']
        ) {
          transaction = {
            from: element.from,
            to: element.to,
            value: Number(
              element?.value / Math.pow(10, Number(element?.tokenDecimal)),
            ).toFixed(4),
            title: 'claim',
            hash: element.hash,
          };
          if (
            element.contractAddress &&
            item.address &&
            element?.contractAddress.toUpperCase() ===
              item.address.toUpperCase()
          ) {
            if (item.history) {
              item.history.push(transaction);
            } else {
              item.history = [transaction];
            }
          }
        } else if (
          listtx?.data?.result.length > 0 &&
          listtx?.data?.result.find((e) => e.hash === element?.hash)
        ) {
          const input = listtx?.data?.result
            .find((e) => e.hash === element?.hash)
            ?.input.slice(0, 10);
          if (input == METHOD['WITHDRAW']) {
            transaction = {
              blockNumber: element?.blockNumber,
              value: Number(
                element?.value / Math.pow(10, Number(element?.tokenDecimal)),
              ).toFixed(4),
              title: 'withDraw',
              hash: element?.hash,
              status: 1,
            };
            if (
              element.contractAddress &&
              item.address &&
              element?.contractAddress.toUpperCase() ===
                item.address.toUpperCase()
            ) {
              if (item.history) {
                item.history.push(transaction);
              } else {
                item.history = [transaction];
              }
            }
          }
        } else {
          transaction =
            element?.from.toUpperCase() == currentAddress.toUpperCase()
              ? {
                  from: element?.from,
                  hash: element?.hash,
                  to: element?.to,
                  value: (
                    Number(element?.value) /
                    Math.pow(10, Number(element?.tokenDecimal))
                  ).toFixed(4),
                  title: 'send',
                }
              : {
                  from: element?.from,
                  hash: element?.hash,
                  value: (
                    Number(element?.value) /
                    Math.pow(10, Number(element?.tokenDecimal))
                  ).toFixed(4),
                  title: 'receive',
                };

          if (
            element.contractAddress &&
            item.address &&
            element?.contractAddress.toUpperCase() ===
              item.address.toUpperCase()
          ) {
            if (item.history) {
              item.history.push(transaction);
            } else {
              item.history = [transaction];
            }
          }
        }
      });
    }
    let transaction;
    let baseTokenTran;
    if (listtx?.data?.result.length > 0) {
      baseTokenTran = listtx?.data?.result.filter(
        (e) => e.value > 0 && e.input === METHOD['SEND_RECEIVE'],
      );
    }
    if (
      listtx?.data?.result.some((e) => e.input === METHOD['SEND_RECEIVE']) &&
      baseTokenTran.length > 0 &&
      item.symbol === baseToken
    ) {
      baseTokenTran.map((tx) => {
        if (!hashBaseToken.includes(tx.hash))
          transaction =
            tx?.from.toUpperCase() == currentAddress.toUpperCase()
              ? {
                  timeStamp: tx.timeStamp,
                  from: tx.from,
                  hash: tx.hash,
                  to: tx.to,
                  value: (Number(tx.value) / 1e18).toFixed(4),
                  title: 'send',
                }
              : {
                  timeStamp: tx.timeStamp,
                  from: tx.from,
                  hash: tx.hash,
                  value: (Number(tx.value) / 1e18).toFixed(4),
                  title: 'receive',
                };

        if (item.symbol == baseToken) {
          if (item.history) {
            item.history.push(transaction);
          } else {
            item.history = [transaction];
          }
          hashBaseToken.push(tx.hash);
        }
      });
    }
    const token = tokenDefault.find((e) => e.symbol === baseToken);
    if (token?.history?.length > 0) {
      token.history.sort(function sort(a, b) {
        return b.timeStamp - a.timeStamp;
      });
    }
    tokenDefault.map((el) => {
      return el.symbol === baseToken ? token : el;
    });
    return {
      item: item,
      token: tokenDefault,
      hashSwap: hashSwap,
      hashBaseToken: hashBaseToken,
      hashInternals: hashInternals,
    };
  }
  const addTokenHaveBalanceToTokenDefaul = async (datas, type) => {
    try {
      if (datas?.data?.result?.length > 0) {
        const allTransitions = datas.data.result;

        let tokenNotDefault = [];
        let listTokenSymbol = [];
        allTransitions &&
          allTransitions?.length > 0 &&
          allTransitions.map((token) => {
            if (
              !tokenDefault.some(function (item) {
                if (item.address) {
                  return item?.address.toLowerCase() == token.contractAddress;
                }
                return false;
              })
            ) {
              listTokenSymbol.push(token.contractAddress);
              tokenNotDefault.push(token);
            }
          });
        const listTokenSymbolUnique = [...new Set(listTokenSymbol)];

        const addNewToken = [];
        listTokenSymbolUnique.map((address) => {
          for (let index = 0; index < tokenNotDefault.length; index++) {
            if (tokenNotDefault[index].contractAddress === address) {
              const tokenNew = {
                address: tokenNotDefault[index].contractAddress,
                symbol: tokenNotDefault[index].tokenSymbol,
                name: tokenNotDefault[index].tokenName,
                decimals: tokenNotDefault[index].tokenDecimal,
                is_default: 1,
                type: type,
              };
              addNewToken.push(tokenNew);
              break;
            }
          }
        });

        if (addNewToken.length > 0) {
          let arrayNewToken = [];
          for (let item of addNewToken) {
            const res = await api.fetcher('get', 'tokens', {
              address: item.address,
            });
            if (res?.data.length > 0) {
              arrayNewToken.push(res?.data[0]);
            } else {
              arrayNewToken.push(item);
            }
          }
          const newTokenDefault = tokenDefault.concat(arrayNewToken);
          setTokenDefault(newTokenDefault);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if ((step === 1, activeWallet)) {
      try {
        async function addTokenNew() {
          const currentAddress = wallet.find(
            (e) => e.is_default === IS_DEFAULT['ACTIVE'],
          )?.address;
          const urlTokentxErc20 =
            getEtherscanCheckTransitionUrl(currentAddress);
          const tokenTxEth = await axios.get(urlTokentxErc20);
          const urlTokentxBsc = getBscScanTransactionUrl(currentAddress);
          const tokenTxBsc = await axios.get(urlTokentxBsc);
          const urlTokentxPolygon =
            getPolygonScanTransactionUrl(currentAddress);
          const tokenTxPolygon = await axios.get(urlTokentxPolygon);
          await addTokenHaveBalanceToTokenDefaul(tokenTxEth, 'token');
          await addTokenHaveBalanceToTokenDefaul(tokenTxBsc, 'bsc');
          await addTokenHaveBalanceToTokenDefaul(tokenTxPolygon, 'matic');
          setStep(2);
          setLoadingToken(false);
        }
        addTokenNew();
      } catch (e) {
        setLoadingToken(false);
      }
    }
  }, [step, activeWallet]);

  useEffect(() => {
    if (
      wallet.some((e) => e.is_default === IS_DEFAULT['ACTIVE']) &&
      tokenDefault.length > 0 &&
      activeWallet &&
      step === 2
    ) {
      async function addHistoryTokenDefault() {
        try {
          const currentAddress = wallet.find(
            (e) => e.is_default === IS_DEFAULT['ACTIVE'],
          )?.address;
          //get address api token type ERC20
          const urlTokentxErc20 =
            getEtherscanCheckTransitionUrl(currentAddress);
          const urlInternalErc20 =
            getEtherscanInternalTransitionUrl(currentAddress);
          const urlListTxErc20 = getEtherscanAllTransitionUrl(currentAddress);
          const tokenTxEth = await axios.get(urlTokentxErc20);
          const tokenInternalEth = await axios.get(urlInternalErc20);
          const allTxEth = await axios.get(urlListTxErc20);
          let hashInternalsEth = [];
          let hashSwapEth = [];
          let hashEth = [];
          // get hash transaction Internal
          if (tokenInternalEth?.data?.result.length > 0) {
            tokenInternalEth?.data?.result.map((el) => {
              hashInternalsEth.push(el?.hash);
            });
          }

          //get address api token type BSC
          const urlTokentxBsc = getBscScanTransactionUrl(currentAddress);
          const urlInternalBsc =
            getBscScanInternalTransitionUrl(currentAddress);
          const urlListTxBsc = getBscScanAllTransitionUrl(currentAddress);
          const tokenTxBsc = await axios.get(urlTokentxBsc);
          const tokenInternalBsc = await axios.get(urlInternalBsc);
          const allTxBsc = await axios.get(urlListTxBsc);
          let hashInternalsBsc = [];
          let hashSwapBsc = [];
          let hashBsc = [];
          // get hash transaction Internal
          if (tokenInternalBsc?.data?.result.length > 0) {
            tokenInternalBsc?.data?.result &&
              tokenInternalBsc?.data?.result.map((el) => {
                hashInternalsBsc.push(el?.hash);
              });
          }

          //get address api token type pOLYGON
          const urlTokentxPolygon =
            getPolygonScanTransactionUrl(currentAddress);
          const urlInternalPolygon =
            getPolygonScanInternalTransitionUrl(currentAddress);

          const urlListTxPolygon =
            getPolygonScanAllTransitionUrl(currentAddress);
          const tokenTxPolygon = await axios.get(urlTokentxPolygon);
          const tokenInternalPolygon = await axios.get(urlInternalPolygon);
          const allTxPolygon = await axios.get(urlListTxPolygon);

          let hashInternalsPolygon = [];
          let hashSwapPolygon = [];
          let hashPolygon = [];
          // get hash transaction Internal
          if (tokenInternalPolygon?.data?.result.length > 0) {
            tokenInternalPolygon?.data?.result &&
              tokenInternalPolygon?.data?.result.map((el) => {
                hashInternalsPolygon.push(el?.hash);
              });
          }

          let _tokenDefault = tokenDefault;
          tokenDefault.forEach((item) => {
            if (
              item.type === TYPE_TOKEN['TOKEN'] ||
              item.type === TYPE_TOKEN['DEFI']
            ) {
              addHistoryToken(
                item,
                _tokenDefault,
                tokenTxEth,
                tokenInternalEth,
                allTxEth,
                hashSwapEth,
                hashInternalsEth,
                hashEth,
                currentAddress,
                'ETH',
                METHODETH,
              ).then((data) => {
                item = data?.item;
                _tokenDefault = data?.token;
                hashSwapEth = data?.hashSwap;
                hashEth = data?.hashBaseToken;
                hashInternalsEth = data?.hashInternals;
              });
            }
            if (item.type === TYPE_TOKEN['BSC']) {
              addHistoryToken(
                item,
                _tokenDefault,
                tokenTxBsc,
                tokenInternalBsc,
                allTxBsc,
                hashSwapBsc,
                hashInternalsBsc,
                hashBsc,
                currentAddress,
                'BNB',
                METHODBSC,
              ).then((data) => {
                item = data?.item;
                _tokenDefault = data?.token;
                hashSwapBsc = data?.hashSwap;
                hashBsc = data?.hashBaseToken;
                hashInternalsBsc = data?.hashInternals;
              });
            }
            if (item.type === TYPE_TOKEN['MATIC']) {
              addHistoryToken(
                item,
                _tokenDefault,
                tokenTxPolygon,
                tokenInternalPolygon,
                allTxPolygon,
                hashSwapPolygon,
                hashInternalsPolygon,
                hashPolygon,
                currentAddress,
                'matic',
                METHODPOLYGON,
              ).then((data) => {
                item = data?.item;
                _tokenDefault = data?.token;
                hashSwapPolygon = data?.hashSwap;
                hashPolygon = data?.hashBaseToken;
                hashInternalsPolygon = data?.hashInternals;
              });
            }
          });
          setTokenDefault(_tokenDefault);
          setLoadingToken(false);
          // }
        } catch (error) {
          // console.log(error);
          setLoadingToken(false);
        }
      }

      addHistoryTokenDefault();
    }
  }, [activeWallet, wallet, step]);

  const updateWallet = async (
    address,
    privateKey,
    mnemonics,
    btcAddress = null,
    btcPrivateKey = null,
  ) => {
    if (wallet.length > 0) {
      const newWallet = [...wallet];
      const index = newWallet.findIndex((val) => val.address == address);
      newWallet.map((wal, idx) => {
        if (wal?.address == address) {
          newWallet[idx].is_default = IS_DEFAULT['ACTIVE'];
          newWallet[idx].privateKey = privateKey;
          newWallet[idx].mnemonics = mnemonics;
          newWallet[idx].btcAddress = btcAddress;
          newWallet[idx].btcPrivateKey = btcPrivateKey;
        } else {
          newWallet[idx].is_default = IS_DEFAULT['UNACTIVE'];
        }
      });
      if (index === -1) {
        setWallet(
          newWallet.concat([
            {
              address: address,
              privateKey: privateKey,
              mnemonics: mnemonics,
              btcAddress: btcAddress,
              btcPrivateKey: btcPrivateKey,
              is_default: IS_DEFAULT['ACTIVE'],
            },
          ]),
        );
      } else {
        setWallet(newWallet);
      }
    } else {
      setWallet([
        {
          address: address,
          privateKey: privateKey,
          mnemonics: mnemonics,
          btcAddress: btcAddress,
          btcPrivateKey: btcPrivateKey,
          is_default: IS_DEFAULT['ACTIVE'],
        },
      ]);
    }
  };

  const removeWallet = async () => {
    const newWallet = [...wallet];
    newWallet.map((wal, idx) => {
      newWallet[idx].is_default = IS_DEFAULT['UNACTIVE'];
    });

    setWallet(newWallet);
  };
  const addWallet = async (
    address,
    privateKey,
    mnemonics,
    btcAddress = null,
  ) => {
    const newWallet = {
      address: address,
      privateKey: privateKey,
      mnemonics: mnemonics,
      btcAddress: btcAddress,
      is_default: IS_DEFAULT['UNACTIVE'],
    };
    setWallet([...wallet, newWallet]);
  };

  // useEffect(() => {
  //   if (wallet != state.wallet) {
  //     dispatch({type: 'SET_WALLET_UPDATE', payload: wallet});
  //   }
  // }, [wallet]);

  const updateTokenDefault = (tokenFavourite) => {
    setTokenDefault(tokenFavourite);
  };
  const addTokenToStore = async (valueArray, type) => {
    const currentAddress = wallet.find(
      (e) => e.is_default === IS_DEFAULT['ACTIVE'],
    )?.address;

    let tokenTx;
    let internalTx;
    let allTx;
    let hashInternals = [];
    let hashSwap = [];
    let hashTx = [];

    if (type == TYPE_TOKEN['DEFI'] || type == TYPE_TOKEN['TOKEN']) {
      const urlTokentxErc20 = getEtherscanCheckTransitionUrl(currentAddress);
      const urlInternalErc20 =
        getEtherscanInternalTransitionUrl(currentAddress);
      const urlListTxErc20 = getEtherscanAllTransitionUrl(currentAddress);

      tokenTx = await axios.get(urlTokentxErc20);
      internalTx = await axios.get(urlInternalErc20);
      allTx = await axios.get(urlListTxErc20);

      // get hash transaction Internal
      if (internalTx?.data?.result.length > 0) {
        internalTx?.data?.result.map((el) => {
          hashInternals.push(el?.hash);
        });
      }
    } else if (type == TYPE_TOKEN['BSC']) {
      const urlTokentxBsc = getBscScanTransactionUrl(currentAddress);
      const urlInternalBsc = getBscScanInternalTransitionUrl(currentAddress);
      const urlListTxBsc = getBscScanAllTransitionUrl(currentAddress);
      tokenTx = await axios.get(urlTokentxBsc);
      internalTx = await axios.get(urlInternalBsc);
      allTx = await axios.get(urlListTxBsc);
      if (internalTx?.data?.result.length > 0) {
        internalTx?.data?.result.map((el) => {
          hashInternals.push(el?.hash);
        });
      }
    } else if (type == TYPE_TOKEN['MATIC']) {
      const urlTokentxPolygon = getPolygonScanTransactionUrl(currentAddress);
      const urlInternalPolygon =
        getPolygonScanInternalTransitionUrl(currentAddress);
      const urlListTxPolygon = getPolygonScanAllTransitionUrl(currentAddress);
      tokenTx = await axios.get(urlTokentxPolygon);
      internalTx = await axios.get(urlInternalPolygon);
      allTx = await axios.get(urlListTxPolygon);

      if (internalTx?.data?.result.length > 0) {
        internalTx?.data?.result.map((el) => {
          hashInternals.push(el?.hash);
        });
      }
    } else {
      const urlTokentxErc20 = getEtherscanCheckTransitionUrl(currentAddress);
      const urlInternalErc20 =
        getEtherscanInternalTransitionUrl(currentAddress);
      const urlListTxErc20 = getEtherscanAllTransitionUrl(currentAddress);

      tokenTx = await axios.get(urlTokentxErc20);
      internalTx = await axios.get(urlInternalErc20);
      allTx = await axios.get(urlListTxErc20);

      // get hash transaction Internal
      if (internalTx?.data?.result.length > 0) {
        internalTx?.data?.result.map((el) => {
          hashInternals.push(el?.hash);
        });
      }
    }
    let _tokenDefault = [];

    if (valueArray.length > 0) {
      async function checkTokenDb() {
        for (let item of valueArray) {
          let _baseToken;
          let _METHOD;
          if (
            item.type === TYPE_TOKEN['TOKEN'] ||
            item.type === TYPE_TOKEN['DEFI']
          ) {
            _baseToken = 'ETH';
            _METHOD = METHODETH;
          } else if (item.type === TYPE_TOKEN['BSC']) {
            _baseToken = 'BNB';
            _METHOD = METHODBSC;
          } else if (item.type === TYPE_TOKEN['MATIC']) {
            _baseToken = 'matic';
            _METHOD = METHODPOLYGON;
          } else {
            _baseToken = 'ETH';
            _METHOD = METHODETH;
          }
          const checkToken = tokenDefault.some(function (el) {
            if (el.address) {
              el?.address.toLowerCase() === item.address;
            } else {
              false;
            }
          });
          const res = await api.fetcher('get', 'tokens', {
            address: item.address,
          });
          if (!checkToken) {
            if (res?.data.length > 0) {
              _tokenDefault.push(res?.data[0]);
            } else {
              _tokenDefault.push(item);
            }
            addHistoryToken(
              item,
              _tokenDefault,
              tokenTx,
              internalTx,
              allTx,
              hashSwap,
              hashInternals,
              hashTx,
              currentAddress,
              _baseToken,
              _METHOD,
            ).then((data) => {
              item = data?.item;
              _tokenDefault = data?.token;
              hashSwap = data?.hashSwap;
              hashTx = data?.hashBaseToken;
              hashInternals = data?.hashInternals;
              const newTokenDefault = tokenDefault.concat(_tokenDefault);
              setTokenDefault(newTokenDefault);
            });
          }
        }
      }
      checkTokenDb();
    }
  };

  const updateTotalMoney = (money) => {
    setTotalMoney(money);
  };

  const updateTransactionHistory = (transaction, symbol) => {
    const newTokenDefault = tokenDefault.map((item) => {
      if (item.symbol == symbol) {
        if (item.history) {
          item.history.unshift(transaction);
          return {
            ...item,
          };
        }
        return {...item, history: [transaction]};
      }

      return item;
    });

    setTokenDefault(newTokenDefault);
  };
  const removeTransactionHistory = (hashTransaction, symbol) => {
    const newTokenDefault = tokenDefault.map((item) => {
      if (item.symbol == symbol) {
        const newHistory = item.history.filter(
          (e) => e.hash !== hashTransaction,
        );

        return {...item, history: newHistory};
      }
      return item;
    });
    setTokenDefault(newTokenDefault);
  };
  const value = {
    wallet,
    tokenDefault,
    tokenAdd,
    totalMoney,
    activeWallet,
    loadingToken,
    updateWallet,
    removeWallet,
    addWallet,
    updateTokenDefault,
    addTokenToStore,
    updateTotalMoney,
    updateTransactionHistory,
    removeTransactionHistory,
  };

  return <AuthContext.Provider value={value} {...props} />;
});

export default AuthProvider;
