import {useEffect, useState} from 'react';
import {useIntersectionObserver} from 'common';
import {Cushion, HeaderText} from 'components';
import {Box, Container, Grid, Stack, styled, Typography} from '@mui/material';
import {NavText} from './NavText';
import StickyBox from 'react-sticky-box';
import {grey} from 'styles/color';
import {useMatchesSize} from 'common/useMatchesSize';
import {fontSize} from '@mui/system';

const PageWithTableOfContent = ({
  pageTitle,
  navItems,
  contentItems,
  boxHeight,
}) => {
  const [visibleId, setVisibleId] = useState(navItems[0].id);

  const {isSmall, isMedium} = useMatchesSize();

  useIntersectionObserver(setVisibleId);

  return (
    <>
      <HeaderBox>
        <HeaderText isGiant pt={10} style={{textAlign: 'center'}}>
          {pageTitle}
        </HeaderText>
      </HeaderBox>
      <Box sx={{background: (theme) => theme.palette.common.white, pb: 20}}>
        {isSmall || isMedium
          ? forMobile(contentItems)
          : forDesktop(navItems, contentItems, boxHeight, visibleId)}
      </Box>
    </>
  );
};

const forDesktop = (navItems, contentItems, boxHeight, visibleId) => {
  return (
    <Container>
      <Grid container>
        <Grid item xs={4}>
          <StickyBox>
            <Cushion />
            <NavBox boxHeight={boxHeight}>
              <Stack height="100%" pt={0.5}>
                {navItems.map((item, index) => (
                  <>
                    <NavText
                      isActive={visibleId === item.id}
                      href={`#${item.id}`}
                      key={item.id + 'navItems'}>
                      {item.type == 'children' ? (
                        <li>{item.text}</li>
                      ) : (
                        item.text
                      )}
                    </NavText>
                  </>
                ))}
              </Stack>
            </NavBox>
          </StickyBox>
        </Grid>
        <Grid item xs={8} sx={{pl: 8}}>
          <Cushion />

          {contentItems.map((item, index) => (
            <>
              <ArticleTitleText
                className="title-nav-item"
                fontSize={item?.type == 'children' ? 20 : 24}
                fontWeight={item?.type == 'children' ? 600 : 600}
                sx={{display: item.title == '' ? 'inline' : 'block'}}
                id={item.id}
                key={item.id + 'contentItems'}>
                {item.title}
              </ArticleTitleText>
              <DetailText variant="description2">{item.content()}</DetailText>
            </>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

const forMobile = (contentItems) => {
  return (
    <Container>
      <Cushion />

      {contentItems.map((item, index) => (
        <>
          <ArticleTitleText
            className="title-nav-item"
            fontSize={item?.type == 'children' ? 22 : 24}
            fontWeight={item?.type == 'children' ? 600 : 600}
            sx={{...(index === 0 && {paddingTop: 0})}}
            id={item.id}
            key={item.id}>
            {item.title}
          </ArticleTitleText>
          <DetailText variant="description2">{item.content()}</DetailText>
        </>
      ))}
    </Container>
  );
};

const HeaderBox = styled(Box)(({theme}) => ({
  height: theme.spacing(40),
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(10),
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.common.white,
}));

const NavBox = styled(Box)(({theme, boxHeight}) => ({
  height: boxHeight,
  borderRight: `solid 1px ${grey[400]}`,
}));

const DetailText = styled(Typography)(({theme}) => ({
  fontSize: theme.spacing(2.25),
  fontWeight: 400,
  lineHeight: '35px',
  color: grey[475],
}));

const ArticleTitleText = styled(Typography)(
  ({theme, fontSize, fontWeight}) => ({
    fontSize: fontSize,
    lineHeight: theme.spacing(4.375),
    fontWeight: fontWeight,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    scrollMarginTop: theme.spacing(10),
    '@supports (-webkit-hyphens:none)': {
      paddingTop: theme.spacing(10),
      marginTop: theme.spacing(-10),
    },
  }),
);

export {PageWithTableOfContent};
