import React, {useCallback, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {Stack, TextField, styled} from '@mui/material';
import {
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  makeStyles,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {ReactComponent as twitter} from 'assets/images/svg/twitter.svg';
import {ReactComponent as linkedin} from 'assets/images/svg/linkedin.svg';
import {ReactComponent as insta} from 'assets/images/svg/insta.svg';
import {ReactComponent as telegram} from 'assets/images/svg/telegram.svg';
import {ReactComponent as discord} from 'assets/images/svg/Discord.svg';
import fgraghLogo from 'assets/images/svg/fgraph-white.svg';
import shinobiLogo from 'assets/images/svg/shinobi-white.svg';
import {ThemeConsumer} from 'react-bootstrap/esm/ThemeProvider';
import {useHistory} from 'react-router';
import {RoundedButton} from 'components';
import {useAPI} from 'api/api';
import axios from 'axios';
import {Text} from 'App';

const link_wp_jp =
  'https://drive.google.com/file/d/1xFzPMIdPYJW1-NY2bLST91TquiYhPFnR/view?usp=sharing';
const link_wp_en =
  'https://drive.google.com/file/d/1DsjtFiV_HaWCMeZgYa4YqE9CbpIk_lm-/view?usp=sharing';

const FooterSection = () => {
  const {t, i18n} = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const handleOpenWhitePaper = () => {
    if (i18n.language == 'ja-JP') {
      window.open(link_wp_jp);
    } else {
      window.open(link_wp_en);
    }
  };
  const handleOpenBlog = () => {
    if (i18n.language === 'ja-JP') {
      window.open('https://note.com/himegami/');
    } else {
      window.open('https://medium.com/@shinobiwallet');
    }
  };
  return (
    <div>
      <div className="footer-section">
        <Container maxWidth={'lg'}>
          <Grid container>
            <Grid item md={6} sm={6} xs={12} className={classes.policy}>
              <img src={shinobiLogo} className={classes.imageLogo} />
              <Typography className={classes.fromLogo}>From</Typography>
              <img src={fgraghLogo} className={classes.imageLogo} />
              <Grid className={classes.socialNetwork}>
                <Tooltip title="Twitter">
                  <IconButton
                    className={classes.iconStyle}
                    onClick={() =>
                      window.open(
                        'https://twitter.com/shinobi_wallet?s=21&t=hhYq4KV4mzG7o2kKybMsaQ',
                        '_blank',
                      )
                    }>
                    <SvgIcon component={twitter} viewBox="0 0 16 18" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Discord">
                  <IconButton
                    className={classes.iconStyle}
                    onClick={() =>
                      window.open('https://discord.gg/fg5fFwYYgt', '_blank')
                    }>
                    <SvgIcon component={discord} viewBox="0 0 40 40" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Telegram">
                  <IconButton
                    className={classes.iconStyle}
                    onClick={() =>
                      window.open('https://t.me/shinobiwallet', '_blank')
                    }>
                    <SvgIcon component={telegram} viewBox="0 0 22 19" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Instagram">
                  <IconButton
                    className={classes.iconStyle}
                    onClick={() =>
                      window.open(
                        'https://www.instagram.com/shinobiwallet/',
                        '_blank',
                      )
                    }>
                    <SvgIcon component={insta} viewBox="0 0 17 16" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Linkedin">
                  <IconButton
                    className={classes.iconStyle}
                    onClick={() =>
                      window.open(
                        'https://ee.linkedin.com/company/shinobi-wallet',
                        '_blank',
                      )
                    }>
                    <SvgIcon component={linkedin} viewBox="0 0 17 16" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid className={classes.socialNetwork}>
                <Text>{t('homepage.subcription.description')}</Text>
                <Stack
                  direction={{md: 'row', xs: 'column'}}
                  spacing={2.25}
                  sx={{paddingTop: 2}}
                  target="_blank"
                  component={'form'}
                  method="post"
                  action="https://safed.us14.list-manage.com/subscribe/post?u=8e1432d71de81b06c6be1a65c&amp;id=3a515264d3">
                  <StyledTextField
                    placeholder={t('homepage.subcription.input_mail')}
                    variant="outlined"
                    className="input-mail-sub"
                    inputProps={{
                      sx: {
                        padding: '12px 14px',
                      },
                    }}
                    name="EMAIL"
                    type="email"
                  />
                  <div aria-hidden="true" style={{display: 'none'}}>
                    <input
                      type="text"
                      name="b_8e1432d71de81b06c6be1a65c_3a515264d3"
                      tabIndex="-1"
                      value=""
                    />
                  </div>
                  <RoundedButton
                    isSmall={false}
                    variant="contained"
                    value="Subscribe"
                    name="subscribe"
                    type="submit"
                    style={{
                      backgroundColor: '#fff',
                      color: '#041562',
                      padding: '0 34px',
                      height: 47,
                      maxWidth: 180,
                    }}>
                    {t('homepage.subcription.subcribe')}
                  </RoundedButton>
                </Stack>
              </Grid>
            </Grid>
            <Grid container item md={6} sm={6} xs={12} justifyContent="center">
              <Grid item md={4} sm={4} xs={6} className={classes.policy}>
                <div className={classes.button}>
                  <Link
                    onClick={() => history.push('/buy-credit-card')}
                    className={classes.buttonFooter}
                    style={{}}>
                    {t('footer.buy_crypto')}
                  </Link>
                </div>
                <div className={classes.button}>
                  <Link
                    className={classes.buttonFooter}
                    onClick={() => history.push('/buy-credit-card')}>
                    {t('footer.market_price')}
                  </Link>
                </div>
                <div className={classes.button} onClick={handleOpenWhitePaper}>
                  <Link className={classes.buttonFooter}>
                    {t('footer.white_paper')}
                  </Link>
                </div>
                <div className={classes.button}>
                  <Link
                    className={classes.buttonFooter}
                    onClick={() => history.push('/white-list')}>
                    WhiteList
                  </Link>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={6} className={classes.policy}>
                <div className={classes.button}>
                  <Link
                    onClick={() => history.push('/about-us')}
                    className={classes.buttonFooter}
                    style={{}}>
                    {t('footer.about')}
                  </Link>
                </div>
                <div className={classes.button}>
                  <Link
                    onClick={() => history.push('/contact')}
                    className={classes.buttonFooter}
                    style={{}}>
                    {t('footer.contact')}
                  </Link>
                </div>
                <div className={classes.button}>
                  <Link
                    className={classes.buttonFooter}
                    onClick={handleOpenBlog}>
                    {t('footer.blog')}
                  </Link>
                </div>

                <div className={classes.button}>
                  <Link
                    className={classes.buttonFooter}
                    onClick={() => history.push('/careers')}>
                    {t('footer.career')}
                  </Link>
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12} className={classes.policy}>
                <div className={classes.button}>
                  <Link
                    className={classes.buttonFooter}
                    onClick={() => history.push('/partners')}>
                    {t('footer.partners')}
                  </Link>
                </div>
                <div className={classes.button}>
                  <Link
                    className={classes.buttonFooter}
                    onClick={() => history.push('/privacy-policy')}>
                    {t('footer.privacy')}
                  </Link>
                </div>
                <div className={classes.button}>
                  <Link
                    className={classes.buttonFooter}
                    onClick={() => history.push('/cookie')}>
                    {t('footer.cookie')}
                  </Link>
                </div>

                <div className={classes.button}>
                  <Link
                    className={classes.buttonFooter}
                    onClick={() => history.push('/terms-of-use')}>
                    {t('footer.term')}
                  </Link>
                </div>

                <div className={classes.button}>
                  <Link
                    className={classes.buttonFooter}
                    onClick={() => history.push('/help-center')}>
                    {t('footer.help')}
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.copyRight}>
            <Typography style={{color: '#fff', fontWeight: 400, fontSize: 16}}>
              © 2022 FGRAPH OU. All Rights Reserved.
            </Typography>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

const StyledTextField = styled(TextField)(({theme}) => ({
  width: theme.spacing(50),
  [theme.breakpoints.down('md')]: {
    width: theme.spacing(40),
  },
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(40),
  },
}));

const useStyles = makeStyles((theme) => ({
  buttonFooter: {
    color: '#fff  ',
    '&:hover': {
      cursor: 'pointer',
      color: '#fff',
    },
    // padding: '10px 0',
    width: '100%',
  },
  policy: {
    paddingTop: 20,
    paddingLeft: 10,
  },
  copyRight: {
    width: '100%',
    // height: 50,
    backgroundColor: '#041562',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #999999',
    marginTop: 70,
    paddingTop: 60,
    paddingBottom: 60,
  },
  button: {
    padding: '5px 0',
    marginBottom: 32,
  },
  socialNetwork: {
    marginTop: theme.spacing(4),
  },
  imageLogo: {
    width: '300px',
    [theme.breakpoints.down('xs')]: {
      width: 250,
    },
  },
  fromLogo: {
    width: 300,
    textAlign: 'center',
    margin: '8px 0 18px 0',
    [theme.breakpoints.down('xs')]: {
      width: 250,
    },
  },
}));
export default FooterSection;
