import {styled} from '@mui/material';
import {grey} from 'styles/color';

const NavText = ({
  children,
  type = 'parents',
  isActive = false,
  href,
  ...props
}) => {
  return (
    <NavTextItem
      isActive={isActive}
      {...props}
      onClick={(e) => {
        e.preventDefault();
        document.querySelector(href).scrollIntoView({
          behavior: 'smooth',
        });
      }}>
      {children}
    </NavTextItem>
  );
};

const NavTextItem = styled('a', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({isActive, theme}) => ({
  textDecoration: 'none',
  color: grey[475],
  fontSize: 16,
  lineHeight: '25px',
  cursor: 'pointer',
  marginTop: 12,
  padding: '6px 0',
  marginRight: '-3px !important',
  ...(isActive && {
    fontWeight: 500,
    color: theme.palette.common.black,
    borderRight: `solid 5px black`,
  }),
}));

export {NavText};
