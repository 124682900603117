import React, {useEffect, useMemo, useState} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Button,
  Typography,
  Checkbox,
  Link,
  SvgIcon,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Text} from 'App';
import {RoundedButton} from 'components';
import CallMadeIcon from '@mui/icons-material/CallMade';
import {ReactComponent as commentIcon} from 'assets/images/svg/comment-info.svg';
import {ReactComponent as phraseIcon} from 'assets/images/svg/phrase.svg';
import {ReactComponent as privatekeyIcon} from 'assets/images/svg/private-key-access.svg';
import {ReactComponent as checkIcon} from 'assets/images/svg/check-circle.svg';
import {useHistory} from 'react-router-dom';

export default function StartAccess({handleContinue}) {
  const useStyles = makeStyles((theme) => ({
    warning: {
      backgroundColor: '#fef8ea',
      padding: '30px 40px',
      marginBottom: 24,
    },
    checkBox: {
      display: 'flex',
      marginTop: 29,
      marginBottom: 37,
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonSelect: {
      border: '1px solid #D2D2D2',
      padding: '20px 0',
      borderRadius: 5,
      marginTop: 16,
      cursor: 'pointer',
    },

    buttonSelected: {
      border: '1px solid #D2D2D2',
      padding: '20px 0',
      borderRadius: 5,
      marginTop: 16,
      cursor: 'pointer',
      backgroundColor: '#F2F5FF',
    },
  }));

  const classes = useStyles();
  const {t} = useTranslation();
  const [type, setType] = useState();
  const history = useHistory();

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item xs={12} style={{textAlign: 'center', marginBottom: 30}}>
        <Text variant="titlePass">Access your wallet</Text>
        <Typography style={{fontSize: 16, color: '#c4c4c4'}}>
          You can access your wallet using any of the following ways.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.warning}>
        <Text
          variant="description1"
          style={{
            color: '#041562',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 6,
          }}>
          <SvgIcon
            component={commentIcon}
            viewBox="0 0 24 24"
            style={{marginRight: 5}}
          />{' '}
          Disclaimer:
        </Text>
        <Text variant="description1" style={{color: '#999999'}}>
          This is not a recommended way to create your wallet. Due to the
          sensitivity of the information involved, these options should only be
          used in offline settings by experienced users.
        </Text>
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={
          type == 'phrase' ? classes.buttonSelected : classes.buttonSelect
        }
        onClick={() => setType('phrase')}>
        <Grid item xs={3} container justifyContent="center" alignItems="center">
          <SvgIcon
            component={phraseIcon}
            viewBox="0 0 32 32"
            fontSize="large"
          />
        </Grid>
        <Grid item xs={8}>
          <Typography style={{fontSize: 16}}>Mnemonic Phrase</Typography>
          <Typography style={{fontSize: 12}}>
            Enter your 12 word phrase in their correct order.
          </Typography>
        </Grid>
        {type == 'phrase' && (
          <Grid
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center">
            <SvgIcon component={checkIcon} viewBox="0 0 24 24" />
          </Grid>
        )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={
          type == 'privateKey' ? classes.buttonSelected : classes.buttonSelect
        }
        onClick={() => setType('privateKey')}>
        <Grid item xs={3} container justifyContent="center" alignItems="center">
          <SvgIcon
            component={privatekeyIcon}
            viewBox="0 0 32 32"
            fontSize="large"
          />
        </Grid>
        <Grid item xs={8}>
          <Typography style={{fontSize: 16}}>Private Key</Typography>
          <Typography style={{fontSize: 12}}>
            Enter your private key to access your wallet.
          </Typography>
        </Grid>
        {type == 'privateKey' && (
          <Grid
            item
            xs={1}
            container
            justifyContent="center"
            alignItems="center">
            <SvgIcon component={checkIcon} viewBox="0 0 24 24" />
          </Grid>
        )}
      </Grid>

      <RoundedButton
        hasIcon
        variant="contained"
        style={{width: 249, marginTop: 42}}
        onClick={() => handleContinue(type)}>
        Verify
      </RoundedButton>
      <Grid item xs={12} className={classes.checkBox}>
        <Typography style={{color: '#999', marginRight: 15}}>
          Don’t have a wallet?
        </Typography>
        <Link
          onClick={() => history.push('/create-wallet')}
          href="#"
          style={{cursor: 'pointer'}}>
          Create wallet
        </Link>
        <CallMadeIcon
          onClick={() => history.push('/create-wallet')}
          sx={{marginLeft: 0.5, color: '#041562', cursor: 'pointer'}}
          fontSize="small"
        />
      </Grid>
    </Grid>
  );
}
