import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  Grid,
  Hidden,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Button,
  Typography,
  Popper,
  Grow,
  SvgIcon,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {useTranslation} from 'react-i18next';
import {AuthContext} from 'store/AuthProvider';
import {IS_DEFAULT} from 'common/constants';
import {formatAddress} from 'common/utils';
import {ReactComponent as qrCode} from '../assets/images/svg/qr-code.svg';
import {ReactComponent as privateKey} from '../assets/images/svg/private-key.svg';
import {ReactComponent as mnemonic} from '../assets/images/svg/mnemonic.svg';
import ModalAddress from 'components/Modal/ModalAddress';
import ModalMnemonic from './Modal/ModalMnemonic';
import ModalPrivateKey from './Modal/ModalPrivateKey';

const WalletAccount = React.memo((props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {wallet, removeWallet} = useContext(AuthContext);
  const [activeWallet, setActiveWallet] = useState();
  const [accDetails, setAccDetails] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalMnemonic, setOpenModalMnemonic] = React.useState(false);
  const [openModalPrivateKey, setOpenModalPrivateKey] = React.useState(false);
  const anchorRef = useRef(null);

  const {t} = useTranslation();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  useEffect(() => {
    if (wallet.length > 0) {
      const index = wallet.findIndex(
        (val) => val.is_default == IS_DEFAULT['ACTIVE'],
      );
      if (index >= 0) {
        setActiveWallet(wallet[index]);
      }
    }
  }, [wallet]);

  const handleCloseList = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleCloseMnemonic = () => {
    setOpenModalMnemonic(false);
  };
  const handleClosePrivateKey = () => {
    setOpenModalPrivateKey(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const logout = async () => {
    await removeWallet();
  };
  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <ListItem
          button
          disableRipple
          edge="start"
          ref={anchorRef}
          onClick={handleToggle}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          style={{padding: '10px 40px'}}>
          <ListItemIcon>
            <AccountBalanceWalletIcon style={{color: '#05c0a5'}} />
            {/* <ArrowDropDownIcon style={{color: '#05c0a5'}} /> */}
          </ListItemIcon>
          <ListItemText primary={'Wallet'} />
          <ListItemSecondaryAction onClick={handleToggle}>
            <ExpandMoreIcon style={{color: '#05c0a5'}} />
          </ListItemSecondaryAction>
        </ListItem>
      </Hidden>
      <Hidden smDown>
        <Button
          disableRipple
          edge="start"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}>
          <AccountBalanceWalletIcon style={{color: '#05c0a5'}} />
          <ArrowDropDownIcon style={{color: '#05c0a5'}} />
        </Button>
      </Hidden>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.modalWallet}>
        {({TransitionProps, placement}) => (
          <Grow {...TransitionProps}>
            <Paper variant="outlined" style={{border: '1px solid #05c0a5'}}>
              <ClickAwayListener onClickAway={handleCloseList}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}>
                  <MenuItem onClick={() => setOpenModal((pre) => !pre)}>
                    <Grid container style={{width: '100%'}}>
                      <Hidden xsDown>
                        <Grid
                          item
                          container
                          justifyContent="center"
                          alignItems="center"
                          md={3}
                          sm={3}
                          xs={3}>
                          <SvgIcon
                            style={{color: '#05c0a5'}}
                            component={qrCode}
                            viewBox="0 0 512 512"
                          />
                        </Grid>
                      </Hidden>
                      <Grid
                        item
                        md={8}
                        sm={8}
                        xs={8}
                        container
                        className={classes.walletAddress}>
                        <Grid>
                          <Typography variant="subtitle1">
                            Wallet Address
                          </Typography>
                        </Grid>
                        <Grid>
                          <Typography
                            variant="body1"
                            className={classes.account}>
                            {formatAddress(activeWallet.address)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      justifyContent="center"
                      alignItems="center"
                      md={1}>
                      <KeyboardArrowRightIcon style={{color: '#05c0a5'}} />
                    </Grid>
                  </MenuItem>

                  {activeWallet.mnemonics && (
                    <MenuItem onClick={() => setOpenModalMnemonic(true)}>
                      <Grid container>
                        <Hidden xsDown>
                          <Grid
                            item
                            container
                            justifyContent="center"
                            alignItems="center"
                            md={3}
                            sm={3}
                            xs={3}>
                            <SvgIcon
                              style={{color: '#05c0a5'}}
                              component={mnemonic}
                              viewBox="0 0 512 512"
                            />
                          </Grid>
                        </Hidden>
                        <Grid item md={9} sm={9} xs={9} container>
                          <Typography variant="subtitle1">
                            {t('mnemonic')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  )}
                  <MenuItem onClick={() => setOpenModalPrivateKey(true)}>
                    <Grid container>
                      <Hidden xsDown>
                        <Grid
                          item
                          container
                          justifyContent="center"
                          alignItems="center"
                          md={3}
                          sm={3}
                          xs={3}>
                          <SvgIcon
                            style={{color: '#05c0a5'}}
                            component={privateKey}
                            viewBox="0 0 24 24"
                          />
                        </Grid>
                      </Hidden>
                      <Grid item md={9} sm={9} xs={9} container>
                        <Typography variant="subtitle1">
                          {t('private key')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>

                  <MenuItem onClick={logout}>
                    <Grid container>
                      <Hidden xsDown>
                        <Grid
                          item
                          container
                          justifyContent="center"
                          alignItems="center"
                          md={3}
                          sm={3}
                          xs={3}>
                          <ExitToAppIcon style={{color: '#05c0a5'}} />
                        </Grid>
                      </Hidden>
                      <Grid item md={9} sm={9} xs={9} container>
                        <Typography variant="subtitle1">
                          {t('change_wallet')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ModalAddress openModal={openModal} handleClose={handleClose} />
      <ModalPrivateKey
        openModal={openModalPrivateKey}
        handleClose={handleClosePrivateKey}
      />
      <ModalMnemonic
        openModal={openModalMnemonic}
        handleClose={handleCloseMnemonic}
      />
    </div>
  );
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'initial',
    marginLeft: 24,
  },
  account: {
    fontStyle: 'italic',
  },
  modalWallet: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      zIndex: 10,
    },
  },
  walletAddress: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
export default WalletAccount;
