const green = {
  600: '#3D7168',
  500: '#4689A3',
  300: '#219653',
  200: '#02fafd',
  50: 'rgba(61, 113, 104, 0.1)',
};

const grey = {
  900: '#333333',
  600: '#4f4f4f',
  550: '#666666',
  500: '#828282',
  475: '#8e8e8e',
  450: '#999999',
  425: '#a4a4a4',
  410: '#acacac',
  400: '#c4c4c4',
  350: '#e5e5e5',
  300: '#f2f2f2',
  200: '#e0e0e0',
  150: '#f3f3f3',
  100: '#f9f9f9',
  50: '#fafafa',
};

const blue = {
  800: '#041562',
  700: '#013178',
  600: '#2f80ed',
  553: 'rgba(20, 42, 249, 0.3)',
  552: 'rgba(20, 42, 249, 0.2)',
  550: '#142af9',
  510: 'rgba(4,83,252,0.35)',
  500: '#0453fc',
  400: '#2b3ac8',
  100: '#f2f5ff',
};

const orange = {
  600: '#f2994a',
};

export {green, grey, blue, orange};
