import React, {useEffect, useMemo, useState} from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Button,
  Typography,
  Checkbox,
  Link,
  SvgIcon,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Text} from 'App';
import {RoundedButton} from 'components';
import shinobiApp from 'assets/images/home/shinobi-app.png';
import createWallet from 'assets/images/create-wallet.png';
import CallMadeIcon from '@mui/icons-material/CallMade';
import {ReactComponent as commentIcon} from 'assets/images/svg/comment-info.svg';
import {useHistory} from 'react-router-dom';

export default function StartCreate({handleCheck, handleContinue, checked}) {
  const useStyles = makeStyles((theme) => ({
    warning: {
      backgroundColor: '#fef8ea',
      padding: '30px 40px',
    },
    checkBox: {
      display: 'flex',
      marginTop: 29,
      marginBottom: 37,
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

  const classes = useStyles();
  const {t} = useTranslation();
  const history = useHistory();

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item xs={12} style={{textAlign: 'center', marginBottom: 30}}>
        <img src={createWallet} />
        <Text variant="titlePass">Create your wallet</Text>
        <Typography style={{fontSize: 16, color: '#c4c4c4'}}>
          In the next step, we will show you 12 words, your recovery phrase,
          with which you can restore your wallet.
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.warning}>
        <Text
          variant="description1"
          style={{
            color: '#041562',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 6,
          }}>
          <SvgIcon
            component={commentIcon}
            viewBox="0 0 24 24"
            style={{marginRight: 5}}
          />{' '}
          Disclaimer:
        </Text>
        <Text
          variant="description1"
          style={{color: '#999999', textAlign: 'center'}}>
          This is not a recommended way to create your wallet. Due to the
          sensitivity of the information involved, these options should only be
          used in offline settings by experienced users.
        </Text>
      </Grid>
      <Grid item xs={11} className={classes.checkBox}>
        <Checkbox checked={checked} onChange={handleCheck} />
        <Typography style={{color: checked ? '#073785' : '#999999'}}>
          If i lose my recovery phrase, I understand that there is no way to
          recover my wallet
        </Typography>
      </Grid>

      <RoundedButton
        hasIcon
        variant="contained"
        style={{width: 249}}
        onClick={handleContinue}>
        Continue
      </RoundedButton>
      <Grid item xs={12} className={classes.checkBox}>
        <Typography style={{color: '#999', marginRight: 15}}>
          Already have a wallet?
        </Typography>
        <Link
          onClick={() => history.push('/access-wallet')}
          style={{cursor: 'pointer'}}>
          Access wallet
        </Link>
        <CallMadeIcon
          onClick={() => history.push('/access-wallet')}
          sx={{marginLeft: 0.5, color: '#041562', cursor: 'pointer'}}
          fontSize="small"
        />
      </Grid>
    </Grid>
  );
}
