import BN from 'bn.js';
// import * as echarts from 'echarts';
import {BTC_NETWORK, COEFFICIENT_GAS_PRICE, MAX_APPROVE_KGR} from './constants';

// export const resizeObserver = new ResizeObserver((entries) => {
//   // eslint-disable-next-line
//   entries.map(({target}) => {
//     const instance = echarts.getInstanceByDom(target);
//     if (instance) {
//       instance.resize();
//     }
//   });
// });

export const isValidAddress = (address) => {
  return /^(0x)?[0-9a-f]{40}$/i.test(address);
};

export const formatCurrency = (source, token) => {
  const NUMBER_FIXED = {
    BASIC: 3,
    BTC: 5,
  };
  if (!source) return 0;
  if (Number.parseFloat(source) % 1 === 0) {
    return Number.parseFloat(source);
  } else {
    if (token === 'BTC' || token === 'ETH') {
      return Number.parseFloat(source).toFixed(NUMBER_FIXED['BTC']);
    } else {
      return Number.parseFloat(source).toFixed(NUMBER_FIXED['BASIC']);
    }
  }
};

export const formatAddress = (address) => {
  if (address) return address.slice(0, 20) + '...' + address.slice(-4);
};
export const formatAddressHistory = (address) => {
  if (address) return address.slice(0, 10) + '...' + address.slice(-4);
};

export const formatDuration = (seconds) => {
  var h = Math.floor(seconds / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor((seconds % 3600) % 60);

  var hDisplay = h >= 0 ? h + 'h:' : '';
  var mDisplay = m >= 0 ? m + 'm:' : '';
  var sDisplay = s >= 0 ? s + 's' : '';
  return hDisplay + mDisplay + sDisplay;
};

export const nFormatter = (num, digits) => {
  var si = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'},
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

export const getGasPrice = async (web3) => {
  try {
    const curGasPrice = await web3.eth.getGasPrice();
    const gasPrice = (curGasPrice * COEFFICIENT_GAS_PRICE)
      .toString()
      .split('.')[0];
    return gasPrice;
  } catch (error) {
    // console.log(error);
  }
};

export const isEnoughETH = async (web3, account, eth) => {
  try {
    const ethBalance = await web3.eth.getBalance(account);
    const ethBalanceBN = new BN(ethBalance);
    const ethAmountBN = new BN(eth);
    if (ethBalanceBN.gte(ethAmountBN)) return true;
    return false;
  } catch (error) {
    // console.log(error);
    return false;
  }
};

export const isEnoughKGR = async (kgrContract, account, kgr) => {
  try {
    const kgrBalance = await kgrContract.methods.balanceOf(account).call();
    const kgrBalanceBN = new BN(kgrBalance);
    const kgrAmountBN = new BN(kgr);
    if (kgrBalanceBN.gte(kgrAmountBN)) return true;
    return false;
  } catch (error) {
    // console.log(error);
    return false;
  }
};

export const isAllowance = async (kgrContract, owner, spender) => {
  try {
    const allowance = await kgrContract.methods
      .allowance(owner, spender)
      .call();

    const allowanceBN = new BN(allowance);
    const maxApproveBN = new BN(MAX_APPROVE_KGR);

    if (allowanceBN.gte(maxApproveBN)) return true;
    return false;
  } catch (error) {
    // console.log(error);
    return false;
  }
};

export function arraysIdentical(a, b) {
  var i = a.length;
  // if (i != b.length) return false;
  while (i--) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}
export function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function checkForDuplicates(array) {
  return new Set(array).size !== array.length;
}
export function formatRate(number) {
  return Number(number).toLocaleString('en-US', {minimumFractionDigits: 2});
}

export function getMainnetUrl() {
  if (process.env.REACT_APP_IS_PRODUCTION.toLowerCase() === 'true') {
    return (
      'https://mainnet.infura.io/v3/' + process.env.REACT_APP_INFURA_API_KEY
    );
  }
  return 'https://ropsten.infura.io/v3/' + process.env.REACT_APP_INFURA_API_KEY;
}
export function getChaiId() {
  if (process.env.REACT_APP_IS_PRODUCTION.toLowerCase() === 'true') {
    return process.env.REACT_APP_CHAIN_ID;
  }
  return process.env.REACT_APP_CHAIN_ID;
}

export function getRewardToken(period, apy, amount, accruedDays) {
  let reward = 0;
  if (Number(period) !== 0 && accruedDays > Number(period)) {
    accruedDays = Number(period);
  }
  const rewardToken = (amount * apy * accruedDays) / (365 * 100);
  reward = rewardToken.toFixed(2);
  return reward;
}

export function getNetworkBtc() {
  if (process.env.REACT_APP_IS_PRODUCTION.toLowerCase() === 'true') {
    return BTC_NETWORK['LIVENET'];
  }
  return BTC_NETWORK['TESTNET'];
}

export function getBlockCypherUrl() {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return 'https://api.blockcypher.com/v1/btc/main';
  }
  return 'https://api.blockcypher.com/v1/btc/test3';
}

export function getUniswapRouterAddress() {
  return process.env.REACT_APP_UNISWAP_ROUTER_ADDRESS;
}

export function getHybridUniswapAddress() {
  return process.env.REACT_APP_HYBRID_UNISWAP_ADDRESS;
}

export function getUkhSwapAddress() {
  return process.env.REACT_APP_UKH_SWAP_ADDRESS;
}
export function getPoolBukhAddress() {
  return process.env.REACT_APP_IPOOL_ADDRESS;
}

export function getEtherscanCheckTransitionUrl(address) {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return `https://api.etherscan.io/api?module=account&action=tokentx&address=${address}&
    startblock=0&endblock=999999999&apikey=SN5YBXD3YRJHU7KCNX2MRSQ9ZKRURDWI5T&sort=desc&apikey=YourApiKeyToken&page=1&offset=50`;
  }
  return `https://api-ropsten.etherscan.io/api?module=account&action=tokentx&address=${address}&
    startblock=0&endblock=999999999&apikey=SN5YBXD3YRJHU7KCNX2MRSQ9ZKRURDWI5T&sort=desc&apikey=SN5YBXD3YRJHU7KCNX2MRSQ9ZKRURDWI5T&page=1&offset=50`;
}
export function getEtherscanInternalTransitionUrl(address) {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return `https://api.etherscan.io/api?module=account&action=txlistinternal&address=${address}&apikey=SN5YBXD3YRJHU7KCNX2MRSQ9ZKRURDWI5T&sort=desc&page=1&offset=50`;
  }
  return `https://api-ropsten.etherscan.io/api?module=account&action=txlistinternal&address=${address}&apikey=SN5YBXD3YRJHU7KCNX2MRSQ9ZKRURDWI5T&sort=desc&page=1&offset=50`;
}
export function getEtherscanAllTransitionUrl(address) {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return `https://api.etherscan.io/api?module=account&action=txlist&address=${address}&apikey=SN5YBXD3YRJHU7KCNX2MRSQ9ZKRURDWI5T&sort=desc&page=1&offset=50`;
  }
  return `https://api-ropsten.etherscan.io/api?module=account&action=txlist&address=${address}&apikey=SN5YBXD3YRJHU7KCNX2MRSQ9ZKRURDWI5T&sort=desc&page=1&offset=50`;
}
export function getBscScanTransactionUrl(address) {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return `https://api.bscscan.com/api?module=account&action=tokentx&address=${address}&
    startblock=0&endblock=999999999&apikey=S1S2293AJVBX7MDS25F3F6V855G6742KZV&sort=desc&page=1&offset=50`;
  }
  return `https://api-testnet.bscscan.com/api?module=account&action=tokentx&address=${address}&
    startblock=0&endblock=999999999&apikey=S1S2293AJVBX7MDS25F3F6V855G6742KZV&sort=desc&page=1&offset=50`;
}
export function getBscScanInternalTransitionUrl(address) {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return `https://api.bscscan.com/api?module=account&action=txlistinternal&address=${address}&apikey=S1S2293AJVBX7MDS25F3F6V855G6742KZV&sort=desc&page=1&offset=50`;
  }
  return `https://api-testnet.bscscan.com/api?module=account&action=txlistinternal&address=${address}&apikey=S1S2293AJVBX7MDS25F3F6V855G6742KZV&sort=desc&page=1&offset=50`;
}
export function getBscScanAllTransitionUrl(address) {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return `https://api.bscscan.com/api?module=account&action=txlist&address=${address}&apikey=S1S2293AJVBX7MDS25F3F6V855G6742KZV&sort=desc&page=1&offset=50`;
  }
  return `https://api-testnet.bscscan.com/api?module=account&action=txlist&address=${address}&apikey=S1S2293AJVBX7MDS25F3F6V855G6742KZV&sort=desc&page=1&offset=50`;
}

export function getPolygonScanTransactionUrl(address) {
  let apiKey = 'FMWT93CHM85TAYS4DRFZHREKKBP4DY9VDC';
  let prefix = '';
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    prefix = 'https://api.polygonscan.com/api?module=account&action=tokentx';
  } else {
    prefix =
      'https://api-testnet.polygonscan.com/api?module=account&action=tokentx';
  }

  const apiUrl = `${prefix}&address=${address}&startblock=0&endblock=999999999&sort=desc&page=1&offset=50&apikey=${apiKey}`;
  return apiUrl;
}
export function getPolygonScanInternalTransitionUrl(address) {
  let apiKey = 'FMWT93CHM85TAYS4DRFZHREKKBP4DY9VDC';
  let prefix = '';
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    prefix = `https://api.polygonscan.com/api?module=account&action=txlistinternal&address=${address}&apikey=${apiKey}&sort=desc&page=1&offset=50`;
  } else {
    prefix = `https://api-testnet.polygonscan.com/api?module=account&action=txlistinternal&address=${address}&apikey=${apiKey}&sort=desc&page=1&offset=50`;
  }
  return prefix;
}
export function getPolygonScanAllTransitionUrl(address) {
  let apiKey = 'FMWT93CHM85TAYS4DRFZHREKKBP4DY9VDC';
  let prefix = '';
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    prefix = 'https://api.polygonscan.com/api?module=account&action=txlist';
  } else {
    prefix =
      'https://api-testnet.polygonscan.com/api?module=account&action=txlist';
  }
  const apiUrl = `${prefix}&address=${address}&startblock=0&endblock=999999999&sort=desc&page=1&offset=50&apikey=${apiKey}`;
  return apiUrl;
}

export function getMaticRpcUrl() {
  if (process.env.REACT_APP_IS_PRODUCTION.toLowerCase() === 'true') {
    return (
      'https://polygon-mainnet.infura.io/v3/' +
      process.env.REACT_APP_INFURA_API_KEY
    );
  }
  return 'https://rpc-mumbai.maticvigil.com/v1/cfa92429599d8071fa4cb57bcdfdd054e54ef5b7';
}

export function getRpcBscUrl() {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return 'https://bsc-dataseed1.binance.org';
  }
  return 'https://data-seed-prebsc-1-s1.binance.org:8545/';
}
export function getChain() {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return 'ethereum';
  }
  return 'ropsten';
}

export function getMaticBridgeAddress() {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return process.env.REACT_APP_MATIC_BRIDGE_ADDRESS;
  }
  return process.env.REACT_APP_MATIC_BRIDGE_ADDRESS;
}
export function getPOSRootChainManager() {
  return process.env.REACT_APP_POS_ROOT_CHAIN_MANAGER || '';
}

export function getMaticUrl() {
  if (process.env.REACT_APP_IS_PRODUCTION.toLowerCase() === 'true') {
    return (
      'https://polygon-mainnet.infura.io/v3/' +
      process.env.REACT_APP_INFURA_API_KEY
    );
  }
  return (
    'https://polygon-mumbai.infura.io/v3/' +
    process.env.REACT_APP_INFURA_API_KEY
  );
}
export function getGoerliUrl() {
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    return (
      'https://mainnet.infura.io/v3/' + process.env.REACT_APP_INFURA_API_KEY
    );
  }
  return process.env.REACT_APP_GOERLI_URL;
}
export function getNetworkInfo() {
  let networkInfo = {
    network: '',
    version: '',
  };
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    networkInfo.network = 'mainnet';
    networkInfo.version = 'v1';
  } else {
    networkInfo.network = 'testnet';
    networkInfo.version = 'mumbai';
  }
  return networkInfo;
}

export function getApiCheckPoint(blockNumber) {
  let prefix = '';
  if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
    prefix = 'https://apis.matic.network/api/v1/matic/block-included';
  } else {
    prefix = 'https://apis.matic.network/api/v1/mumbai/block-included';
  }
  const apiUrl = `${prefix}/${blockNumber}`;
  return apiUrl;
}

export function getApiKeyTransak() {
  return process.env.REACT_APP_API_KEY_TRANSAK;
}

export function getDomainTransak() {
  return process.env.REACT_APP_DOMAIN_TRANSAK;
}

export function getApiTransak() {
  return process.env.REACT_APP_API_DOMAIN_TRANSAK;
}

export function getEnvTransak() {
  return process.env.REACT_APP_ENV_TRANSAK;
}
export function getWidgetDomainMercuryo() {
  return process.env.REACT_APP_WIDGET_DOMAIN_MERCURYO;
}

export function getWidgetIDMercuryo() {
  return process.env.REACT_APP_WIDGET_ID_MERCURYO;
}

export function getApiWidgetMecuryo() {
  return process.env.REACT_APP_API_URL_MERCURYO;
}
